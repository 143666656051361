const useSubTitleStyles = (width: number) => {
  const style: React.CSSProperties = {}

  switch (true) {
    case width > 1600:
      style.fontSize = 28
      style.letterSpacing = 10
      style.fontWeight = 500
      break
    case width > 1024 && width < 1600:
      style.fontSize = 26
      style.letterSpacing = 10
      style.fontWeight = 500
      break
    case width > 510 && width < 1024:
      style.fontSize = 24
      style.letterSpacing = 5
      style.fontWeight = 500
      break
    case width <= 510:
      style.fontSize = 20
      style.letterSpacing = 5
      style.fontWeight = 500
      break
    default:
      style.fontSize = 22
      style.letterSpacing = 10
      style.fontWeight = 500
      break
  }

  return style
}

export default useSubTitleStyles
