import { FC } from 'react'
import '../css/WorkAndEducation.css'
type Props = {
  experience: string
  date: { start: number; end: number | string }
  description?: string
}

const WorkAndEducation: FC<Props> = ({ experience, date, description }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'start',
      }}
    >
      <div className="workAndEducationHeader">
        <p
          style={{
            fontWeight: 600,
            textAlign: 'start',
            width: '75%',
          }}
        >
          {experience}
        </p>

        <div
          style={{
            width: '25%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p
            style={{
              fontWeight: 600,
              textAlign: 'start',
            }}
          >
            {date.start}
          </p>
          <p
            style={{
              fontWeight: 600,
            }}
          >
            -
          </p>
          <p
            style={{
              alignSelf: 'flex-start',
              fontWeight: 600,
              textAlign: 'start',
            }}
          >
            {date.end}
          </p>
        </div>
      </div>
      <div className="workAndEducationContent">
        <p style={{ marginTop: -10, fontSize: 14 }}>{description}</p>
      </div>
    </div>
  )
}

export default WorkAndEducation
