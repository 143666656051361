import '../components/css/Cv.css'
import CvHeader from '../components/cv/CvHeader'
import LeftCvColumn from '../components/LeftCvColumn'
import RightCvColumn from '../components/RightCvColumn'
import { getThemeColors } from '../components/utils/ThemeUtils'

const Cv = () => {
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')

  return (
    <>
      <div
        className="cvProfileContainer"
        style={{
          backgroundColor: themeColor.main,
        }}
      >
        <CvHeader />
        <div className="leftRightContainer">
          <LeftCvColumn />
          <RightCvColumn />
        </div>
      </div>
    </>
  )
}

export default Cv
