import { FC, useRef } from 'react'
import * as THREE from 'three'

import { Sphere, useTexture } from '@react-three/drei'

type Props = {
  position: [x: number, y: number, z: number]
}
const Saturn: FC<Props> = ({ position }) => {
  const stripeTexture = useTexture('/materials/saturnMaterial.jpg')
  const carpetTexture = useTexture('/materials/carpet.jpg')
  const planetRef = useRef(null)
  const ringRef = useRef(null)
  const torus = new THREE.TorusGeometry(4, 1.25, 2, 60)

  return (
    <group position={position} scale={0.7} rotation-y={1}>
      <mesh ref={planetRef}>
        <Sphere args={[2, 20, 20]}>
          <meshStandardMaterial
            map={carpetTexture}
            color="white"
            attach="material"
            emissive="#ffffd8"
            emissiveIntensity={0.25}
          />
        </Sphere>
      </mesh>
      <mesh
        ref={ringRef}
        geometry={torus}
        rotation={[Math.PI / 2, 0, 0]}
        rotation-x={1}
      >
        <meshStandardMaterial
          map={stripeTexture}
          color="#93936c"
          attach="material"
          emissive="#93936c"
          emissiveIntensity={0.15}
        />
      </mesh>
    </group>
  )
}

export default Saturn
