import '../css/DownloadPdf.css'
import { DownloadIcon } from '../../SVG'
import { getThemeColors } from '../utils/ThemeUtils'

const DownloadPdf = () => {
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')

  return (
    <div className="downloadContainer">
      <div className="btnAndTxt">
        <a
          href={'http://localhost:3000/MartinSjorCV.pdf'}
          download="MartinSjorCV.pdf"
          className="download-link"
        >
          <button
            className="downloadBtn"
            style={{ backgroundColor: themeColor.background }}
          >
            <DownloadIcon className="downloadIcon" color={themeColor.main} />
          </button>
        </a>
        <p>Last ned pdf</p>
      </div>
    </div>
  )
}

export default DownloadPdf
