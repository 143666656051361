import { FC, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import './css/Email.css'

type Props = {
  toggleShowMailForm: () => void
  themeTxtColor: string
}
const Email: FC<Props> = ({ toggleShowMailForm, themeTxtColor }) => {
  const form = useRef<HTMLFormElement>(null)
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [mailIsSent, setMailIsSent] = useState(false)

  const [mailSummary, setMailSummary] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: '',
  })

  const userInfoIsFilled =
    fullName !== '' && email !== '' && subject !== '' && message !== ''

  const saveMail = () => {
    setMailSummary({
      fullName,
      email,
      subject,
      message,
    })
  }

  const sendEmail = (e: any) => {
    e.preventDefault()
    if (form.current !== null) {
      emailjs
        .sendForm(
          'service_hda9lvn',
          'template_d9fnjqf',
          form.current,
          '7tW996uE2j3uuka8U'
        )
        .then(
          (result) => {
            // Reset form fields after successful email send
            setFullName('')
            setEmail('')
            setSubject('')
            setMessage('')
            setMailIsSent(true)

            // Here you can write code for what should happen after sending the email
          },
          (error) => {
            console.log(error.text)
            // Alert or something else here
          }
        )
    }
  }

  return (
    <>
      <div className="emailContainer">
        {!mailIsSent ? (
          <>
            <button onClick={() => toggleShowMailForm()} className="exitBtn">
              <p style={{ fontWeight: '800' }}>Lukk</p>
            </button>
            <form
              ref={form}
              onSubmit={sendEmail}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div className="input-container">
                <label
                  htmlFor="fullName"
                  className="email-label"
                  style={{ color: themeTxtColor }}
                >
                  Fullt navn:
                </label>
                <input
                  type="text"
                  name="user_name"
                  value={fullName}
                  className="inputField"
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
              <div className="input-container">
                <label
                  htmlFor="email"
                  className="email-label"
                  style={{ color: themeTxtColor }}
                >
                  E-post:
                </label>
                <input
                  type="email"
                  name="user_email"
                  value={email}
                  className="inputField"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-container">
                <label
                  htmlFor="subject"
                  className="email-label"
                  style={{ color: themeTxtColor }}
                >
                  Emne:
                </label>
                <input
                  type="text"
                  name="subject"
                  className="inputField"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </div>
              <div style={{ display: 'flex' }} className="input-container">
                <label
                  htmlFor="message"
                  className="email-label"
                  style={{ alignSelf: 'center', color: themeTxtColor }}
                >
                  Melding:
                </label>
                <textarea
                  name="message"
                  value={message}
                  className="textArea"
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
              <button
                onClick={saveMail}
                type="submit"
                className="sendBtn"
                disabled={userInfoIsFilled ? false : true}
              >
                Send
              </button>
            </form>
          </>
        ) : (
          <div className="confirmationContainer">
            <div
              style={{
                width: '70%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h5
                style={{
                  fontWeight: '600',
                  color: 'white',
                }}
              >
                Mailen er sendt.
                <br /> Bekreftelse er sendt til:
              </h5>
              <h5
                style={{
                  fontWeight: '600',
                  color: 'white',
                  margin: '-4px 0px',
                }}
              >
                {mailSummary.email.charAt(0).toUpperCase() +
                  mailSummary.email.slice(1)}
              </h5>
            </div>
            <button
              onClick={() => {
                toggleShowMailForm()
              }}
              className="exitBtn"
            >
              <p style={{ fontWeight: '800', textDecoration: 'none' }}>Lukk</p>
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default Email
