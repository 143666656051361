import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { getThemeColors } from './utils/ThemeUtils'
import './css/NavigationButton.css'
import { ScreenIds } from './NavigationBar'
import { useDispatch } from 'react-redux'
import { setCurrentScreen } from '../Redux/reducers/AppReducer'

type Props = {
  title: string
  path: string
  currentPage: string
  id: ScreenIds
  setCurrentPage: (id: ScreenIds) => void
}

const NavigationButton: FC<Props> = ({
  title,
  path,
  currentPage,
  id,
  setCurrentPage,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const className =
    theme === 'LIGHT'
      ? `nav-button ${currentPage === id ? 'active' : ''}`
      : `nav-button ${currentPage === id ? 'dark' : ''}`

  return (
    <button
      onClick={() => {
        navigate(path)
        setCurrentPage(id)
        dispatch(setCurrentScreen(id))
      }}
      className={className}
      style={{
        zIndex: 10,
        width: 150,
        height: 40,
      }}
    >
      <p
        className="navigationText"
        style={{
          color: themeColor.navigationBar,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          margin: 0,
        }}
      >
        {title}
      </p>
    </button>
  )
}

export default NavigationButton
