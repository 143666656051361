import React from 'react'
import * as THREE from 'three'
import { Text, Text3D, useAnimations, useGLTF } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { CuboidCollider, Physics, RigidBody } from '@react-three/rapier'
import { useEffect, useRef, useState } from 'react'
import font from '../fonts/Itim_Regular.json'

const FallingTextAnimation = ({ isAnimating }: { isAnimating: boolean }) => {
  const druidRef = useRef()
  const { scene, animations } = useGLTF(
    'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/druid/model.gltf'
  )
  const [druidSpellDone, setDruidSpellDone] = useState(false)
  const [rotateScene, setRotateScene] = useState(false)
  const { actions } = useAnimations(animations, druidRef)
  const [makeLettersFall, setMakeLettersFall] = useState(false)
  type TimeoutId = ReturnType<typeof setTimeout>
  const [animationIsDone, setAnimationIsDone] = useState(false)

  const { camera } = useThree()

  const rotationSpeed = 0.012
  const rotationCenter = new THREE.Vector3(0, 0, 0)
  let rotationAngle = 0
  const maxRotationAngle = Math.PI * 4

  useEffect(() => {
    if (isAnimating) {
      setAnimationIsDone(false)
    }
  }, [isAnimating])

  useFrame(() => {
    if (rotateScene && !animationIsDone) {
      if (camera.position) {
        if (rotationAngle < maxRotationAngle) {
          camera.position.x =
            Math.cos(rotationSpeed) * (camera.position.x - rotationCenter.x) -
            Math.sin(rotationSpeed) * (camera.position.z - rotationCenter.z) +
            rotationCenter.x

          camera.position.z =
            Math.sin(rotationSpeed) * (camera.position.x - rotationCenter.x) +
            Math.cos(rotationSpeed) * (camera.position.z - rotationCenter.z) +
            rotationCenter.z
          camera.lookAt(rotationCenter)
          rotationAngle += rotationSpeed
        } else {
          setRotateScene(false)
          setAnimationIsDone(true)

          rotationAngle = 0
          const newCameraPosition = new THREE.Vector3(-2, 0.5, 18)

          const distance = camera.position.distanceTo(newCameraPosition)
          const stepAmount = 0.03

          if (distance > stepAmount) {
            const direction = newCameraPosition
              .clone()
              .sub(camera.position)
              .normalize()
            const step = direction.multiplyScalar(stepAmount)
            camera.position.add(step)
          } else {
            camera.position.copy(newCameraPosition)
          }
        }
      }
    }
  })

  useEffect(() => {
    let timeoutId: TimeoutId
    if (!isAnimating) {
      setMakeLettersFall(false)
      setDruidSpellDone(false)
      setRotateScene(false)
    } else if (isAnimating && druidSpellDone) {
      timeoutId = setTimeout(() => {
        actions.PortalOpen?.play()
      }, 5000)
      timeoutId = setTimeout(() => {
        setRotateScene(true)
        actions.PortalOpen?.stop()
      }, 7500)
    }

    if (actions.Waiting !== null) {
      actions.Waiting.play()
    } else return

    if (!druidSpellDone && isAnimating && !animationIsDone) {
      if (actions.PortalOpen !== null) {
        actions.PortalOpen.play()
        setDruidSpellDone(true)
      } else return
    } else if (druidSpellDone && isAnimating) {
      timeoutId = setTimeout(() => {
        if (actions.PortalOpen !== null) {
          actions.PortalOpen?.stop()
        } else return
      }, 3000)
    }
    if (isAnimating && !animationIsDone) {
      timeoutId = setTimeout(() => {
        setMakeLettersFall(true)
      }, 1300)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isAnimating, druidSpellDone])

  const animationTxt = [
    '3',
    'D',
    '',
    'A',
    'N',
    'I',
    'M',
    'A',
    'T',
    'I',
    'O',
    'N',
  ]
  const fjernDenne = false
  console.log(makeLettersFall, 'makelettersfall')
  return (
    <>
      <directionalLight castShadow position={[0, 3, 4]} intensity={1} />
      <ambientLight intensity={0.5} />

      <Physics>
        {/* NYTT */}
        {/* {animationTxt.map((char, index) => {
          const randomRotation = (Math.random() - 0.7) * 0.7
          const xPosition = -6 + index
          return (
            <React.Fragment key={index}>
              <RigidBody
                restitution={0}
                friction={0}
                gravityScale={makeLettersFall ? 0.4 : 0}
              >
                {Array.from({ length: 11 }).map((_, index) => (
                  <Text3D
                    key={index}
                    // @ts-ignore
                    font={font}
                    scale={1}
                    castShadow
                    rotation={[randomRotation, 0, 0]}
                    position={[xPosition, 0.75, 0.75 * 0.01]}
                  >
                    {char}
                    <meshStandardMaterial color="rgba(25,5,25)" />
                  </Text3D>
                ))}
              </RigidBody>
            </React.Fragment>
          )
        })} */}
        <RigidBody colliders="trimesh">
          <mesh
            geometry={new THREE.ConeGeometry(3, 1.5, 10, 10)}
            scale={0.5}
            position={[3, -4.5, 0]}
          >
            <meshStandardMaterial color="lightblue" opacity={0} transparent />
          </mesh>
        </RigidBody>
        <RigidBody colliders="trimesh">
          <mesh
            geometry={new THREE.ConeGeometry(3, 1.5, 10, 10)}
            scale={0.5}
            position={[-2, -4.5, 0]}
          >
            <meshStandardMaterial color="lightblue" opacity={0} transparent />
          </mesh>
        </RigidBody>
        {/* NYTT FERDIG */}

        <>
          {animationTxt.map((char, index) => {
            // Beregner x-posisjon basert på indeksen
            const xPosition = -6 + index
            return (
              <React.Fragment key={index}>
                {makeLettersFall ? (
                  <RigidBody
                    key={index}
                    restitution={0}
                    friction={0}
                    gravityScale={makeLettersFall ? 0.4 : 0}
                  >
                    <mesh castShadow position={[xPosition, 3.9, 0]}>
                      <CuboidCollider
                        args={[0.5, 0.5, 0.3]}
                        position={[0, 0.5, 0]}
                        restitution={1}
                      />
                      {Array.from({ length: 11 }).map((_, index) => (
                        <Text
                          key={index}
                          castShadow
                          fontSize={1.5}
                          position={[0, 0.75, 0.75 * 0.01]}
                          color="rgb(20,20,20)"
                        >
                          {char}
                        </Text>
                      ))}
                    </mesh>
                  </RigidBody>
                ) : (
                  <mesh castShadow position={[xPosition, 3.9, 0]}>
                    {/* <boxGeometry args={[0.5, 1, 0.3]} /> */}
                    <CuboidCollider
                      args={[0.5, 1, 0.3]}
                      position={[0, 0.5, 0]}
                      restitution={1}
                    />
                    {Array.from({ length: 11 }).map((_, index) => (
                      <Text
                        castShadow
                        key={index}
                        fontSize={1.5}
                        position={[0, 0.75, index * 0.01]}
                        color="rgb(20,20,20)"
                        anchorX="center"
                        anchorY="middle"
                      >
                        {char}
                      </Text>
                    ))}
                  </mesh>
                )}
              </React.Fragment>
            )
          })}
        </>

        <primitive
          ref={druidRef}
          object={scene}
          position={[0, -4.7, 0]}
          scale={2}
        />
        <CuboidCollider args={[1, 0.5, 1]} position={[0, -3.2, 0]} />
        <CuboidCollider args={[0.2, 1.2, 1]} position={[0, -3.2, 0]} />

        <RigidBody type="fixed" friction={0.7}>
          <mesh receiveShadow position-y={-5.25}>
            <boxGeometry args={[40, 0.5, 30]} />
            <meshStandardMaterial transparent opacity={0} />
          </mesh>
        </RigidBody>
      </Physics>
    </>
  )
}

export default FallingTextAnimation
