import { Canvas } from '@react-three/fiber'
import FallingTextAnimation from './FallingTextAnimation'
import './css/FallingTextAnimation.css'
import './css/ThreeFiberPresentation.css'
import { Sparkles } from '@react-three/drei'
import Saturn from '../models/Saturn'
import Planet from '../models/Planet'
import { useSelector } from 'react-redux'
import Typewriter from './Typewriter'
import { getThemeColors } from './utils/ThemeUtils'
import useTypeWriterStyles from './utils/hooks/TypeWriterStyles'
import RoughPlanet from '../models/RoughPlanet'
import ShootingStars from '../models/ShootingStars'
import CompasSection from './compas-section'

const ThreeFiberPresentation = () => {
  const { width, scroll } = useSelector((state: any) => state.app)
  // const startAnimation = scroll >= 1100 && scroll <= 1750 ? true : false
  // const startAnimation = scroll >= 440 && scroll <= 1000 ? true : false
  const startAnimation = scroll >= 1875 && scroll <= 2340 ? true : false

  return (
    <>
      <FiberHeader />
      <CompasSection />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
        }}
      >
        <div className="fallingTextContainer">
          <Canvas
            style={{
              height: '500px',
              marginBottom: '100px',
              maxWidth: '1400px',
              touchAction: 'none',
              pointerEvents: 'none',
            }}
            shadows
            camera={{
              fov: 45,
              near: 0.1,
              far: 200,
              position: [-2, 0.5, width <= 500 ? 28 : 18],
            }}
          >
            <FallingTextAnimation isAnimating={startAnimation} />
            <Planet
              planetColor="#FF8C00"
              emissiveColor="#FF8C00"
              position={[0, 5, 26]}
              scale={2}
            />
            <Planet
              planetColor="white"
              emissiveColor="yellow"
              position={[-20, -2, 50]}
              scale={1}
            />
            <Planet
              planetColor="orange"
              emissiveColor="gold"
              position={[-60, 4, 20]}
              scale={1.3}
            />

            <Planet
              planetColor="white"
              emissiveColor="#ffffd8"
              position={[100, 5, 100]}
              scale={5}
            />

            <RoughPlanet position={[-35, -14, -60]} scale={1} />
            <ShootingStars />
            <Saturn position={[50, 3.5, -20]} />
            <Sparkles
              color={'lightblue'}
              size={18}
              scale={[100, 70, 100]}
              count={800}
              speed={0.2}
            />
          </Canvas>
        </div>
      </div>
    </>
  )
}

export default ThreeFiberPresentation

const FiberHeader = () => {
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const { width, scroll } = useSelector((state: any) => state.app)
  const typeWriterStyle = useTypeWriterStyles(width)

  const startTyping = scroll > 560 ? true : false
  return (
    <div className="fiberHeaderContainer">
      {width <= 667 ? (
        <>
          <Typewriter
            key={'R'}
            delay={50}
            startDelay={1000}
            text={'REACT'}
            className={'fiberHeader'}
            style={{
              color: themeColor.projectText,
              marginTop: 280,
              ...typeWriterStyle,
            }}
            startTyping={startTyping}
          />
          <Typewriter
            key={'3F'}
            delay={50}
            startDelay={2200}
            text={'3 FIBER'}
            className={'fiberHeader'}
            style={{
              color: themeColor.projectText,
              marginBottom: '100px',
              ...typeWriterStyle,
            }}
            startTyping={startTyping}
          />
        </>
      ) : (
        <Typewriter
          key={'R3F'}
          delay={50}
          startDelay={1900}
          text={'REACT THREE FIBER'}
          className={'fiberHeader'}
          style={{
            color: themeColor.projectText,
            marginTop: '240px',
            marginBottom: '100px',
            ...typeWriterStyle,
          }}
          startTyping={startTyping}
        />
      )}
    </div>
  )
}
