import React from 'react'
import { useGLTF } from '@react-three/drei'

useGLTF.preload('/compassHinge.glb')

const CompassHinge = ({
  position,
  scale,
  material,
}: {
  position: [number, number, number]
  scale: number
  material: any
}) => {
  // @ts-ignore
  const { nodes } = useGLTF('/compassHinge.glb')
  return (
    <group
      position={position}
      scale={scale}
      rotation={[Math.PI / 2, Math.PI, Math.PI]}
      dispose={null}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder003.geometry}
        material={material}
        position={[-0.23, 0, 0]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.05, 0.07, 0.05]}
      />
    </group>
  )
}

export default CompassHinge
