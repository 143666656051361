import { FC } from 'react'
import { getThemeColors } from '../utils/ThemeUtils'
import WorkAndEducation from './WorkAndEducation'

type WorkAndEducationType = {
  id: string
  experience: string
  date: { start: number; end: number | string }
  description?: string
}[]
type Props = {
  header: string
  array: WorkAndEducationType
}

const ExperienceSection: FC<Props> = ({ header, array }) => {
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')

  return (
    <>
      <div
        className="columnHeaders"
        style={{ backgroundColor: themeColor.background }}
      >
        <h2
          className="cvHeaders"
          style={{
            color: themeColor.main,
          }}
        >
          {header}
        </h2>
      </div>
      {array.map((a: any) => {
        return (
          <WorkAndEducation
            key={a.id}
            experience={a.experience}
            date={a.date}
            description={a.description}
          />
        )
      })}
    </>
  )
}

export default ExperienceSection
