import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ScreenIds } from '../../components/NavigationBar'

const initialScreenWidth = window.innerWidth

type AppState = {
  theme: 'LIGHT' | 'DARK'
  currentScreen: ScreenIds
  width: number
  scroll: number
  introIsDone: boolean
  startFirstSceneAnimation: boolean
  isMovingForward: boolean | null
  threeMenuOpen: boolean
}

const initialState: AppState = {
  theme: 'LIGHT',
  currentScreen: 'EXPERTISE',
  width: initialScreenWidth,
  scroll: 0,
  introIsDone: false,
  startFirstSceneAnimation: false,
  isMovingForward: null,
  threeMenuOpen: false,
}

const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<'LIGHT' | 'DARK'>) => {
      state.theme = action.payload
    },

    setCurrentScreen: (state, action: PayloadAction<ScreenIds>) => {
      state.currentScreen = action.payload
    },
    setWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload
    },
    setScroll: (state, action: PayloadAction<number>) => {
      state.scroll = action.payload
    },
    setIntroIsDone: (state, action: PayloadAction<boolean>) => {
      state.introIsDone = action.payload
    },
    setStartFirstSceneAnimation: (state, action: PayloadAction<boolean>) => {
      state.startFirstSceneAnimation = action.payload
    },
    setIsMovingForward: (state, action: PayloadAction<boolean>) => {
      state.isMovingForward = action.payload
    },
    setThreeMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.threeMenuOpen = action.payload
    },
  },
})

export const {
  setCurrentScreen,
  setTheme,
  setWidth,
  setScroll,
  setIntroIsDone,
  setStartFirstSceneAnimation,
  setIsMovingForward,
  setThreeMenuOpen,
} = appReducer.actions

export default appReducer.reducer
