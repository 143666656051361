// themeUtils.ts
const aboutDark = { backgroundColor: '#ffba3b', borderColor: '#ffba3b' }
const aboutLight = { backgroundColor: 'white', borderColor: 'white' }

const lightTheme = {
  main: 'white',
  background: 'rgb(34, 49, 66)',
  backgroundGraphColor: 'lightgray',
  projectText: 'rgb(20,20,20)',
  navigationBar: 'black',
  text: 'black',
  aboutBackground: 'white',
  aboutShort: aboutLight,
  aboutTall: aboutLight,
}

const darkTheme = {
  main: 'rgb(34, 49, 66)',
  background: 'white',
  backgroundGraphColor: 'black',
  projectText: 'white',
  navigationBar: 'white',
  text: 'white',
  aboutBackground: 'lightgray',
  aboutShort: aboutDark,
  aboutTall: aboutDark,
}

export const getThemeColors = (theme: string) => {
  return theme === 'LIGHT' ? lightTheme : darkTheme
}
