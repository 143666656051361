import React from 'react'
import { useGLTF, useTexture } from '@react-three/drei'

import * as THREE from 'three'

useGLTF.preload('/compassBottom.glb')

const CompassBottom = ({
  position,
  scale,
  material,
}: {
  position: [number, number, number]
  scale: number
  material: any
}) => {
  // @ts-ignore
  const { nodes } = useGLTF('/compassBottom.glb')
  const compassTexture = useTexture('/compassBG.jpg')
  const cylinderGeometry = new THREE.CylinderGeometry(1, 1, 0.1)
  const compassBGmaterial = new THREE.MeshStandardMaterial({
    map: compassTexture,
  })

  const cylinder_bg_Geometry = new THREE.CylinderGeometry(0.18, 0.18, 0.1, 30)

  const bronzeMaterial = new THREE.MeshStandardMaterial({
    metalness: 1,
    roughness: 0.25,
    color: '#cd7f32',
  })

  return (
    <>
      <mesh
        scale={1.5}
        geometry={cylinderGeometry}
        material={compassBGmaterial}
        position={[0, 0, 0.25]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
      >
        <group
          position={position}
          scale={scale}
          dispose={null}
          rotation={[0, 0, Math.PI]}
        >
          <mesh
            geometry={cylinder_bg_Geometry}
            rotation={[0, -Math.PI / 2, 0]}
            position={[0, 0.141, 0]}
            material={bronzeMaterial}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder.geometry}
            material={material}
            position={[0, 0.06, 0]}
            scale={[0.909, 0.04, 0.909]}
          />
        </group>
      </mesh>
    </>
  )
}

export default CompassBottom
