import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentScreen } from '../Redux/reducers/AppReducer'

import './css/PhoneNavigationButton.css'
import { ScreenIds } from './NavigationBar'

type Props = {
  title: string
  path: string
  currentPage: string
  id: ScreenIds
  setCurrentPage: (id: ScreenIds) => void
}

const PhoneNavigationButton: FC<Props> = ({
  title,
  path,
  currentPage,
  id,
  setCurrentPage,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <button
      onClick={() => {
        navigate(path)
        setCurrentPage(id)
        dispatch(setCurrentScreen(id))
      }}
      className={`phoneNav-button ${currentPage === id ? 'active' : ''}`}
      style={{
        zIndex: 10,
        width: 150,
        height: 40,
      }}
    >
      <p className="phoneNavigationText" style={{ color: 'white' }}>
        {title}
      </p>
    </button>
  )
}

export default PhoneNavigationButton
