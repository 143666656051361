import './css/WorkoutAppPhone.css'
import { getThemeColors } from './utils/ThemeUtils'
import Session from '../pictures/projects/sessionR.png'
import Statistics from '../pictures/projects/statisticsR.png'
import createProgram from '../pictures/projects/createProgR.png'
import Typewriter from './Typewriter'
import { useSelector } from 'react-redux'
import useTypeWriterStyles from './utils/hooks/TypeWriterStyles'
import useSubTitleStyles from './utils/hooks/SubTitleStyles'
import { Information } from './staticInfo/Information'
import { ReactTechType } from './types/types'

const WorkoutAppPhone = () => {
  const { width, scroll } = useSelector((state: any) => state.app)
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const typeWriterStyle = useTypeWriterStyles(width)
  const subtitleStyle = useSubTitleStyles(width)

  const firstScrollValue = 1895 + 600
  const secondScrollValue = 2345 + 600
  const thirdScrollValue = 2770 + 600

  const secondImageScroll = `workoutImagePhone ${
    scroll >= firstScrollValue ? 'firstMove' : ''
  }`
  const thirdImageScroll = `workoutImagePhone ${
    scroll >= secondScrollValue
      ? 'firstMove secondMove'
      : scroll >= firstScrollValue
      ? 'firstMove'
      : ''
  }`

  const description =
    scroll >= thirdScrollValue
      ? `workoutImagePhone ${
          scroll >= thirdScrollValue
            ? 'thirdMove'
            : scroll >= firstScrollValue
            ? 'firstMove'
            : ''
        }`
      : scroll >= secondScrollValue
      ? thirdImageScroll
      : scroll >= firstScrollValue
      ? secondImageScroll
      : 'hide'

  return (
    <div className="workoutContainerPhone">
      <div
        style={{
          width: '100%',
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className="descriptionContainerPhone">
          <Typewriter
            delay={50}
            startDelay={1900}
            text={'REACT NATIVE'}
            style={{
              color: themeColor.projectText,
              alignSelf: 'center',
              ...typeWriterStyle,
            }}
            startTyping={scroll > 1260 ? true : false}
          />
          <div className={description}>
            {Information.nativeTechStack.map((n: ReactTechType) => {
              return (
                <p
                  key={n.id}
                  className="codeDescriptionPhone"
                  style={{
                    color: themeColor.projectText,
                    ...subtitleStyle,
                    opacity: scroll < thirdScrollValue ? 0 : 1,
                    width: scroll < thirdScrollValue ? 20 : 300,
                  }}
                >
                  {n.title}
                </p>
              )
            })}
          </div>
        </div>
      </div>
      <img
        src={Session}
        className="workoutImagePhone"
        alt="Hvordan en økt kan se ut i appen"
        style={{
          zIndex: 2,
        }}
      />

      <img
        src={createProgram}
        className={secondImageScroll}
        alt="Hvordan du kan lage programmer i appen"
        style={{
          zIndex: 1,
        }}
      />
      <img
        src={Statistics}
        alt="Statistikk som presenteres i appen"
        className={thirdImageScroll}
        style={{
          zIndex: 0,
        }}
      />
    </div>
  )
}

export default WorkoutAppPhone
