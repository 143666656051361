import { FC, useEffect } from 'react'
import { useState } from 'react'
import './css/Typewriter.css'

type Props = {
  text: string
  delay: number
  startDelay: number
  style?: React.CSSProperties
  className?: string
  startTyping?: boolean
}
const Typewriter: FC<Props> = ({
  text,
  delay,
  startDelay,
  style,
  className,
  startTyping,
}) => {
  const [currentText, setCurrentText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [startAnimation, setStartAnimation] = useState(false)
  const showTyping = startTyping === undefined ? true : startTyping

  setTimeout(() => {
    setStartAnimation(true)
  }, startDelay)

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    setTimeout(() => {
      setStartAnimation(true)
    }, startDelay)

    if (startAnimation && showTyping) {
      if (currentIndex < text.length) {
        timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex])
          setCurrentIndex((prevIndex) => prevIndex + 1)
        }, delay)
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [currentIndex, delay, text, startAnimation, showTyping, startDelay])

  return (
    <>
      {currentText.length > 0 ? (
        <span className={className} style={{ ...style }}>
          {currentText}
        </span>
      ) : (
        <span className={className} style={{ ...style, opacity: 0 }}>
          {text}
        </span>
      )}
    </>
  )
}

export default Typewriter
