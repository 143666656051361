import { useEffect } from 'react'
import CompassSectionOnSmallDevices from './compass-section-on-small-devices'
import ReactThreeCanvas from './ReactThreeCanvas'
import Compass from '../models/compass'
import { useSelector } from 'react-redux'

const CompasSection = () => {
  // const black = themeColors.backgroundColor
  const COMPASS_STYLE = {
    position: 'relative',
    height: 900,
    // paddingBottom: 210,
    zIndex: 0, // BEHOLD DENNE(PGA NAVBAR)
    // marginBottom: 150,
    touchAction: 'none',
    margin: 'auto',
  }

  const { width } = useSelector((state: any) => state.app)
  const onSmallDevice = width === null ? 0 : width < 1024

  // TVINGER UPDATE AV SIDE VED ENDRING AV SKJERMSTØRRELSE (TRENGTE IKKE FORCEUPDATE-STATE)
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth
      const onSmallDevice = newWidth === null ? 0 : newWidth < 1024
      if (onSmallDevice !== (width === null ? 0 : width < 1024)) {
        // setForceUpdate((prevState) => !prevState)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  return onSmallDevice ? (
    <>
      <CompassSectionOnSmallDevices />
    </>
  ) : (
    <>
      <ReactThreeCanvas
        style={COMPASS_STYLE}
        cameraPosition={[0, 0, -5] as [number, number, number]}
      >
        {/* <TextInterface setMailIsVisible={setMailIsVisible} /> */}
        <Compass
          position={[0, -0.15, 0]}
          // isVisible={divFade.opacity === 1}
          isVisible={true}
        />
      </ReactThreeCanvas>
    </>
  )
}

export default CompasSection
