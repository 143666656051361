import { useEffect, useRef, useState } from 'react'
import './css/WorkoutApp.css'
import { getThemeColors } from './utils/ThemeUtils'
import WorkoutAppSmall from './WorkoutAppPhone'
import Session from '../pictures/projects/sessionR.png'
import Statistics from '../pictures/projects/statisticsR.png'
import createProgram from '../pictures/projects/createProgR.png'
import Typewriter from './Typewriter'
import { useSelector } from 'react-redux'
import { Information } from './staticInfo/Information'
import { ReactTechType } from './types/types'
import useTypeWriterStyles from './utils/hooks/TypeWriterStyles'
import useSubTitleStyles from './utils/hooks/SubTitleStyles'

const WorkoutApp = () => {
  const { width, scroll } = useSelector((state: any) => state.app)
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const typeWriterStyle = useTypeWriterStyles(width)
  const subtitleStyle = useSubTitleStyles(width)

  const myRef = useRef<any>()

  let animationStartScrollValue = 1770 + 760
  if (width > 510 && width <= 800) {
    animationStartScrollValue = 1910 + 760
  } else if (width > 800 && width <= 1100) {
    animationStartScrollValue = 1790 + 760
  } else if (width > 1100) {
    animationStartScrollValue = 1760 + 760
  }
  // let animationStartScrollValue = 1770
  // if (width > 510 && width <= 800) {
  //   animationStartScrollValue = 1910
  // } else if (width > 800 && width <= 1100) {
  //   animationStartScrollValue = 1790
  // } else if (width > 1100) {
  //   animationStartScrollValue = 1760
  // }

  const startAnimationValue = scroll - animationStartScrollValue

  const calculateMaxAnimationValue = (width: number) => {
    if (width < 1110 && width > 511) {
      if (width < 740) {
        return 110
      } else if (width < 900) {
        return 160
      }

      return 190
    }
    return 190
  }

  const maxAnimationValue = calculateMaxAnimationValue(width)
  const [showCodeDescription, setShowCodeDescription] = useState(false)

  const translationNumber = Math.min(
    Math.max(0, startAnimationValue),
    maxAnimationValue
  )

  useEffect(() => {
    if (translationNumber === maxAnimationValue) {
      setTimeout(() => {
        setShowCodeDescription(true)
      }, 300)
    } else {
      setShowCodeDescription(false)
    }
  }, [translationNumber, maxAnimationValue])

  return (
    <>
      <div className="workoutAppContainer" ref={myRef}>
        {width <= 510 ? (
          <>
            <WorkoutAppSmall />
          </>
        ) : (
          <>
            <div
              style={{
                position: 'relative',
              }}
            >
              <img
                src={Session}
                className="workoutImage"
                alt="Hvordan en økt kan se ut i appen"
                style={{
                  zIndex: 2,
                }}
              />
              <img
                src={createProgram}
                className="workoutImage"
                alt="Hvordan du kan lage programmer i appen"
                style={{
                  // transform: `translateX(${translationNumber * 1.5}px)`,
                  transform: `translateX(${translationNumber * 1.5}px)`,
                  zIndex: 1,
                }}
              />
              <img
                src={Statistics}
                className="workoutImage"
                alt="Statistikk som presenteres i appen"
                style={{
                  transform: `translateX(${translationNumber * 3}px)`,
                  zIndex: 0,
                }}
              />
            </div>
            {width > 510 && width <= 1100 ? (
              <>
                <Typewriter
                  delay={50}
                  startDelay={1000}
                  text={'REACT NATIVE'}
                  style={{
                    ...typeWriterStyle,
                    color: themeColor.projectText,
                    textAlign: 'center',
                    marginBottom: scroll > 2180 ? 50 : 30,
                  }}
                  startTyping={scroll > 2180 ? true : false}
                />
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: width < 680 ? 'center' : '',
                  }}
                >
                  {Information.nativeTechStack.map((n: ReactTechType) => {
                    return (
                      <p
                        key={n.id}
                        className="workoutcodeDescription"
                        style={{
                          color: themeColor.projectText,
                          ...subtitleStyle,
                        }}
                      >
                        {n.title}
                      </p>
                    )
                  })}
                </div>
              </>
            ) : (
              <>
                <Typewriter
                  delay={50}
                  startDelay={1000}
                  text={'REACT NATIVE'}
                  style={{
                    ...typeWriterStyle,
                    color: themeColor.projectText,
                    position: width > 1100 ? 'relative' : 'static',
                    left: '13%',
                    // marginBottom: '400px',
                  }}
                  startTyping={scroll > 1100 ? true : false}
                />

                <div
                  className="workoutdescriptionContainer"
                  style={{
                    opacity: showCodeDescription ? 1 : 0,
                  }}
                >
                  {Information.nativeTechStack.map((n: ReactTechType) => {
                    return (
                      <p
                        key={n.id}
                        className="workoutcodeDescription"
                        style={{
                          color: themeColor.projectText,
                          ...subtitleStyle,
                        }}
                      >
                        {n.title}
                      </p>
                    )
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default WorkoutApp
