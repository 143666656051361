import { Float, Sphere, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { FC, useRef } from 'react'

type Props = {
  planetColor: string
  emissiveColor: string
  scale: number
  position: [x: number, y: number, z: number]
}
const Planet: FC<Props> = ({ planetColor, emissiveColor, scale, position }) => {
  const sunRef = useRef<any>()

  const carpetTexture = useTexture('/materials/carpet.jpg')

  useFrame(() => {
    if (sunRef !== null) {
      sunRef.current.rotation.y -= 0.001
    }
  })
  return (
    <Float rotationIntensity={0.2}>
      <mesh ref={sunRef} position={position} scale={scale}>
        <Sphere args={[1.5, 20, 20]}>
          <meshStandardMaterial
            map={carpetTexture}
            color={planetColor}
            attach="material"
            emissive={emissiveColor}
            emissiveIntensity={0.15}
          />
        </Sphere>
      </mesh>
    </Float>
  )
}

export default Planet
