import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Information } from './staticInfo/Information'
import Mexico from '../pictures/about/mexico.jpg'
import Paris from '../pictures/about/paris.jpg'
import Telttur from '../pictures/about/telttur.jpg'
import Backflip from '../pictures/about/backflip.jpg'

const ImageAndTxtSelector = () => {
  const width = useSelector((state: any) => state.app.width)
  type Images = {
    firstImage: { src: string; alt: string }
    secondImage: { src: string; alt: string }
    thirdImage: { src: string; alt: string }
    fourthImage: { src: string; alt: string }
  }
  type Txt = {
    header: string
    description: string
  }
  type TxtBox = {
    firstTxtBox: Txt
    secondTxtBox: Txt
    thirdTxtBox: Txt
    fourthTxtBox: Txt
  }

  const paris = {
    src: Paris,
    alt: 'Biketrip to Paris',
  }
  const mexico = {
    src: Mexico,
    alt: 'Travel to Mexico',
  }
  const tentTrip = {
    src: Telttur,
    alt: 'Tent trip',
  }
  const backflip = {
    src: Backflip,
    alt: 'Backflip',
  }

  const updateImages = (width: number): Images => {
    if (width <= 620) {
      return {
        firstImage: tentTrip,
        secondImage: mexico,
        thirdImage: paris,
        fourthImage: backflip,
      }
    } else if (width <= 960) {
      return {
        firstImage: tentTrip,
        secondImage: backflip,
        thirdImage: mexico,
        fourthImage: paris,
      }
    } else {
      return {
        firstImage: tentTrip,
        secondImage: backflip,
        thirdImage: mexico,
        fourthImage: paris,
      }
    }
  }

  const [images, setImages] = useState(updateImages(width))

  const updateTxtBoxes = (width: number): TxtBox => {
    if (width <= 620) {
      return {
        firstTxtBox: Information.aboutInfo.aboutMe,
        secondTxtBox: Information.aboutInfo.activities,
        thirdTxtBox: Information.aboutInfo.bike,
        fourthTxtBox: Information.aboutInfo.travel,
      }
    } else if (width <= 960) {
      return {
        firstTxtBox: Information.aboutInfo.aboutMe,
        secondTxtBox: Information.aboutInfo.travel,
        thirdTxtBox: Information.aboutInfo.activities,
        fourthTxtBox: Information.aboutInfo.bike,
      }
    } else {
      return {
        firstTxtBox: Information.aboutInfo.activities,
        secondTxtBox: Information.aboutInfo.travel,
        thirdTxtBox: Information.aboutInfo.aboutMe,
        fourthTxtBox: Information.aboutInfo.bike,
      }
    }
  }

  const [txtBox, setTxtBox] = useState({
    firstTxtBox: Information.aboutInfo.activities,
    secondTxtBox: Information.aboutInfo.bike,
    thirdTxtBox: Information.aboutInfo.aboutMe,
    fourthTxtBox: Information.aboutInfo.travel,
  })

  useEffect(() => {
    const newImages = updateImages(width)
    setImages(newImages)
    const newTxtBox = updateTxtBoxes(width)
    setTxtBox(newTxtBox)
  }, [width])

  return { images, txtBox }
}

export default ImageAndTxtSelector
