const useTypeWriterStyles = (width: number) => {
  const style: React.CSSProperties = {}

  const fontFamily = 'Tourney'

  switch (true) {
    case width > 1600:
      style.fontFamily = fontFamily
      style.fontSize = 44
      style.letterSpacing = 20
      style.fontWeight = 600
      break
    case width > 1024 && width < 1600:
      style.fontFamily = fontFamily
      style.fontSize = 40
      style.letterSpacing = 15
      style.fontWeight = 600
      break
    case width > 510 && width < 1024:
      style.fontFamily = fontFamily
      style.fontSize = 32
      style.letterSpacing = 15
      style.fontWeight = 600
      break
    case width <= 510:
      style.fontFamily = fontFamily
      style.fontSize = 32
      style.letterSpacing = 10
      style.fontWeight = 600
      break
    default:
      style.fontSize = 32
      style.fontFamily = fontFamily
      style.letterSpacing = 15
      style.fontWeight = 600
      break
  }

  return style
}

export default useTypeWriterStyles
