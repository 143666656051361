import {
  ContactShadows,
  Float,
  Html,
  PresentationControls,
  useGLTF,
} from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { useRef, useState } from 'react'
import * as THREE from 'three'

const Iphone = ({
  startAnimation,
  cameraDistance,
}: {
  startAnimation: boolean
  cameraDistance: number
}) => {
  const { scene } = useGLTF(
    'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/iphone-x/model.gltf'
  )

  const cameraPosition = new THREE.Vector3(-2, 8, 40)
  const { camera } = useThree()
  const finalCameraPosition = new THREE.Vector3(2, 2, cameraDistance)
  const [showIframe, setShowIframe] = useState(false)
  const ref = useRef<any>([0, 0, 0])

  useFrame((state: any, delta) => {
    if (startAnimation) {
      const cameraTarget = new THREE.Vector3(0, 0, 0)
      camera.position.lerp(finalCameraPosition, 0.028)
      camera.lookAt(cameraTarget)
      if (ref.current.rotation.y < Math.PI * 2) {
        ref.current.rotation.y += delta * 2.2
      } else {
        setShowIframe(true)
      }
    } else {
      camera.position.copy(cameraPosition)
      camera.lookAt(new THREE.Vector3(0, 0, 0))
      setShowIframe(false)
      ref.current.rotation.y = 0
    }
  })

  return (
    <>
      <group position={[0, -1, -5]}>
        <ambientLight intensity={0.5} />
        <PresentationControls
          global
          rotation={[0.13, 0.4, 0]}
          polar={[-0.4, 0.2]}
          azimuth={[-1, 0.75]}
          config={{ mass: 2, tension: 400 }}
          snap={{ mass: 4, tension: 400 }}
        >
          <Float rotationIntensity={0.4}>
            <primitive
              ref={ref}
              position={[-1.4, -1.5, 0]}
              object={scene}
              scale={1.3}
            >
              <Html
                transform
                wrapperClass="iphoneScreen"
                distanceFactor={1.5}
                position={[0.165, 1.34, 0.06]}
              >
                <iframe
                  title="MartinSjor Iphone"
                  // src="https://martinsjor.com"
                  src="https://www.endringslederne.no/"
                  style={{
                    opacity: showIframe ? '100%' : '0%',
                  }}
                />
              </Html>
            </primitive>
          </Float>
        </PresentationControls>
      </group>
      <ContactShadows
        position-y={-2.9}
        position-z={-0.3}
        position-x={-0.15}
        opacity={0.5}
        blur={2.4}
      />
    </>
  )
}

export default Iphone
