import React, { FC, Suspense } from 'react'
import { Canvas } from '@react-three/fiber'

type Props = {
  children: any
  style?: any
  cameraPosition?: [number, number, number]
}
const ReactThreeCanvas: FC<Props> = ({ children, cameraPosition, style }) => {
  return (
    <>
      <Suspense
        fallback={
          <div style={{ height: 400 }}>
            {/* <p style={{ fontSize: 50, textAlign: 'center', color: 'white' }}>
              Loading....
            </p> */}
          </div>
        }
      >
        <Canvas
          // className={Styles.canvas}
          style={style}
          shadows
          camera={{
            fov: 45,
            // aspect: window.innerWidth / window.innerHeight,
            near: 0.1,
            far: 200,
            position: cameraPosition,
          }}
        >
          {children}
        </Canvas>
      </Suspense>
    </>
  )
}

export default ReactThreeCanvas
