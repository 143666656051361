import { useState } from 'react'
import { MailIcon, PhoneIcon } from '../SVG'
import './css/Cv.css'
import Email from './Email'
import Typewriter from './Typewriter'
import { getThemeColors } from './utils/ThemeUtils'
import { Information } from './staticInfo/Information'
import { ProfilePointersType } from './types/types'
import LanguageAndExpertise from './cv/LanguageAndExpertise'

const LeftCvColumn = () => {
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const imgBorder = `4px solid ${themeColor.background}`
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)
  const [showEmailForm, setShowEmailForm] = useState(false)

  const toggleShowMailForm = () => {
    setShowEmailForm(!showEmailForm)
  }

  return (
    <>
      <div
        className="mailContent"
        style={{
          position: 'fixed',
          left: showEmailForm ? '15px' : '-100%',
          top: showEmailForm ? '35%' : '50%',
          zIndex: '10',
        }}
      >
        {showEmailForm && (
          <Email
            toggleShowMailForm={toggleShowMailForm}
            themeTxtColor="white"
          />
        )}
      </div>
      <div className="columnContainer">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            className="cornerBox"
            style={{ backgroundColor: themeColor.background }}
          />
          <div
            className="cvProfileImgContainer"
            style={{ backgroundColor: themeColor.main }}
          >
            <img
              className="cvProfileImg"
              src={require('../pictures/about/profilePic.png')}
              alt="Profile"
              style={{ alignSelf: 'center', border: imgBorder }}
            />
          </div>

          <div className="pointersContainer">
            {Information.profilePointers.map((t: ProfilePointersType) => {
              return (
                <Typewriter
                  key={t.id}
                  startDelay={t.startDelay}
                  delay={t.delay}
                  text={`・ ${t.pointer}`}
                  style={{
                    marginBottom: 2,
                  }}
                />
              )
            })}
          </div>

          <div className="contactContainer">
            <button
              onClick={toggleShowMailForm}
              className="contactBtn"
              style={{ backgroundColor: themeColor.background }}
            >
              <MailIcon color={themeColor.main} />
            </button>

            <button
              className="contactBtn delay"
              onClick={() => setShowPhoneNumber(!showPhoneNumber)}
              style={{ backgroundColor: themeColor.background }}
            >
              <PhoneIcon color={themeColor.main} />
            </button>
          </div>
          {showPhoneNumber && (
            <div className="pointersContainer">
              <p
                className="pointers"
                style={{
                  marginBottom: '0px',
                }}
              >
                ・ 93037104
              </p>
            </div>
          )}
          <LanguageAndExpertise />
        </div>
      </div>
    </>
  )
}

export default LeftCvColumn
