import { Float, Sphere, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { CuboidCollider, Physics } from '@react-three/rapier'
import { FC, useRef } from 'react'

type Props = {
  scale: number
  position: [x: number, y: number, z: number]
}
const RoughPlanet: FC<Props> = ({ scale, position }) => {
  const planetRef = useRef<any>()

  const carpetTexture = useTexture({
    disp: '/materials/carpet.jpg',
    rough: '/textures/aerial_rocks_04_rough_1k.jpg',
    map: '/textures/aerial_rocks_04_diff_1k.jpg',
  })
  useFrame(() => {
    if (planetRef !== null) {
      planetRef.current.rotation.y -= 0.001
    }
  })
  return (
    <Physics>
      <Float rotationIntensity={0.2}>
        <CuboidCollider
          args={[0.5, 0.5, 0.3]}
          position={[0, 0.5, 0]}
          restitution={1}
        />
        <mesh ref={planetRef} position={position} scale={scale}>
          <Sphere args={[10, 30, 30]}>
            <meshStandardMaterial
              map={carpetTexture.map}
              displacementMap={carpetTexture.disp}
              roughnessMap={carpetTexture.rough}
            />
          </Sphere>
          <Sphere args={[10.2, 30, 30]}>
            <meshStandardMaterial color={'rgb(49,41,26)'} />
          </Sphere>
        </mesh>
      </Float>
    </Physics>
  )
}

export default RoughPlanet
