import '../components/css/MacReact.css'
import {
  ContactShadows,
  Float,
  Html,
  PresentationControls,
  useGLTF,
} from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { FC, useRef, useState } from 'react'
import * as THREE from 'three'
import Lights from '../components/Lights'

type Props = {
  startAnimation: boolean
  cameraDistance: number
}

const MacReact: FC<Props> = ({ startAnimation, cameraDistance }) => {
  const theme = localStorage.getItem('theme')
  const macRef = useRef<any>()
  const [screenRotation, setScreenRotation] = useState(3.135)
  const [showIframe, setShowIframe] = useState(false)
  const cameraPosition = new THREE.Vector3(-2, 8, 40)

  const { camera } = useThree()
  const { scene } = useGLTF(
    'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf'
  )
  if (scene) {
    scene.children[0].children[15].rotation.x = screenRotation // Initial value screen
    scene.children[0].children[8].visible = false
  }

  const finalCameraPosition = new THREE.Vector3(2, 2, cameraDistance)

  useFrame((state: any, delta) => {
    if (startAnimation) {
      const cameraTarget = new THREE.Vector3(0, 0, 0)

      // Animerer kameraposisjonen fra cameraPosition til finalCameraPosition i jevnt tempo
      camera.position.lerp(finalCameraPosition, 0.028) // Juster 0.1 etter ønsket animasjonshastighet
      camera.lookAt(cameraTarget)

      if (screenRotation > 1.2) {
        setScreenRotation(
          (scene.children[0].children[15].rotation.x -= 0.8 * delta)
        )
      } else {
        setShowIframe(true)
      }
    } else {
      camera.position.copy(cameraPosition)
      camera.lookAt(new THREE.Vector3(0, 0, 0))
      setScreenRotation(3.135)
      setShowIframe(false)
    }
  })

  return (
    <>
      <Lights />
      {showIframe && (
        <rectAreaLight
          width={2.5}
          height={1.65}
          intensity={65}
          color={theme === 'LIGHT' ? 'rgb(95,128,144)' : 'rgb(15,59,79)'}
          rotation={[0.5, Math.PI, 0]}
          position={[0, 0.55, -1]}
        />
      )}

      <PresentationControls
        rotation={[0.13, 0.4, 0]}
        polar={[-0.4, 0.2]}
        azimuth={[-1, 0.75]}
        config={{ mass: 2, tension: 400 }}
        snap={{ mass: 4, tension: 400 }}
      >
        <Float rotationIntensity={0.4}>
          <primitive ref={macRef} object={scene} position-y={-1.2} scale={1}>
            {showIframe && (
              <Html
                transform
                wrapperClass="htmlScreen"
                distanceFactor={1.17}
                position={[0, 1.5, -1.55]}
                rotation-x={-0.35}
              >
                <iframe
                  title="MartinSjor.com"
                  // src="https://martinsjor.com/"
                  src="https://www.endringslederne.no/"
                  style={{
                    opacity: showIframe ? '100%' : '0%',
                  }}
                />
              </Html>
            )}
          </primitive>
        </Float>
      </PresentationControls>
      <ContactShadows position-y={-1.4} opacity={0.4} scale={5} blur={2.4} />
    </>
  )
}

export default MacReact
