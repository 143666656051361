'use client'
import React, { FC, useRef } from 'react'
import { Float, PresentationControls } from '@react-three/drei'
import * as THREE from 'three'
import CompassBottom from './compass-bottom'
import CompassLid from './compass-lid'
import CompassHinge from './compass-hinge'
import { useFrame } from '@react-three/fiber'
import CompPoles from './comp_poles'
import SpotlightCirclingMesh from '../components/spotlight-circling-mesh'
import { useSelector } from 'react-redux'

type Props = {
  position: [number, number, number]
  isVisible: boolean
}
const Compass: FC<Props> = ({ position, isVisible }) => {
  const bronzeMaterial = new THREE.MeshStandardMaterial({
    metalness: 1,
    roughness: 0.25,
    color: '#cd7f32',
  })

  const { width } = useSelector((state: any) => state.app)
  const smallScreen = width === null ? false : width < 767

  const lidGroupRef = useRef<THREE.Group>(null)

  useFrame((_, delta) => {
    if (lidGroupRef.current && isVisible) {
      if (lidGroupRef.current.rotation.x < 3.3) {
        lidGroupRef.current.rotation.x += (delta * Math.PI) / 15
      }
    }
  })
  const geometry = new THREE.CylinderGeometry(1.65, 1.65, 0.2, 64)

  return (
    <>
      <Float
        floatIntensity={smallScreen ? 0.4 : 1}
        rotationIntensity={smallScreen ? 0.4 : 1}
      >
        <SpotlightCirclingMesh meshPosition={position} />
        {/* <ambientLight intensity={0.6} /> */}
        <ambientLight intensity={1.5} />
        <PresentationControls
          // enabled={!smallScreen ? true : false} // the controls can be disabled by setting this to false
          enabled={width === null ? false : width < 1024 ? false : true} // the controls can be disabled by setting this to false
          global={true} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={true} // Snap-back to center (can also be a spring config)
          speed={2} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          polar={[-Math.PI / 5, Math.PI / 3]} // Vertical limits
          azimuth={[-Math.PI / 3, Math.PI / 3]} // Horizontal limits
          config={{ mass: 1, tension: 300, friction: 16 }} // Spring config
        >
          <group
            scale={0.4}
            castShadow
            receiveShadow
            position={position}
            rotation={[0.3, 0, 0]}
          >
            <group
              scale={1}
              ref={lidGroupRef}
              position={[0, 1.95, 0.15]}
              rotation={[2.7, 0, 0]}
            >
              <CompassLid
                scale={1.65}
                position={[0, 0, 1.65]}
                material={bronzeMaterial}
              />

              <mesh
                geometry={geometry}
                material={bronzeMaterial}
                position={[0, 0, -1.65]}
                visible={false}
              />
            </group>
            <CompassHinge
              scale={1.5}
              position={[0, 1.73, 0.1]}
              material={bronzeMaterial}
            />
            <CompassBottom
              scale={1.1}
              position={[0, 0.16, 0]}
              material={bronzeMaterial}
            />
            <CompPoles smallScreen={smallScreen} material={bronzeMaterial} />
            <pointLight
              position={[0, 0.9, 4]}
              intensity={1.5}
              color={'#cd7f32'}
            />

            {smallScreen && (
              <pointLight position={[0, -0.2, -2]} intensity={0.2} />
            )}
            {!smallScreen && (
              <pointLight position={[0, -0.2, -1]} intensity={0.4} />
            )}
          </group>
          {/* FINJUSTER DEN UNDER,- FOR Å SE LOKKET  */}
          {/* <pointLight position={[0, -1, -4]} intensity={0.6} /> */}
        </PresentationControls>
      </Float>
    </>
  )
}

export default Compass
