import { configureStore, combineReducers } from '@reduxjs/toolkit'
import AppReducer from './reducers/AppReducer'

const rootReducer = combineReducers({
  app: AppReducer,
})

const store = configureStore({
  reducer: rootReducer,
})

export default store
