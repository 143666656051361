import { useRef, useState, useEffect } from 'react'
import { Float, Sphere, useTexture } from '@react-three/drei'
import { Vector3 } from 'three'
import { useFrame } from '@react-three/fiber'

const getRandomPosition = () => {
  const randomX = Math.random() * 50 + 290
  const randomY = Math.random() * 5 - 0
  const randomZ = Math.random() * -10 - 30
  return new Vector3(randomX, randomY, randomZ)
}

const getRandomSize = () => Math.random() * -2.5 + 2

const ShootingStars = () => {
  const shootingStarsRef = useRef<any>()
  const [shootingStars, setShootingStars] = useState<Array<any>>([])

  const generateShootingStars = () => {
    const newShootingStars = Array.from({ length: 10 }, (_, index) => ({
      position: getRandomPosition(),
      size: getRandomSize(),
    }))
    setShootingStars(newShootingStars)
  }

  useEffect(() => {
    generateShootingStars()
  }, [])

  const terrainTexture = useTexture({
    map: '/textures/aerial_rocks_04_diff_1k.jpg',
    disp: '/textures/aerial_rocks_04_disp_1k.png',
    rough: '/textures/aerial_rocks_04_rough_1k.jpg',
  })

  useFrame(() => {
    if (shootingStarsRef !== null) {
      if (shootingStarsRef.current.position.x > -500) {
        shootingStarsRef.current.position.x += -1
        shootingStarsRef.current.position.z += -0.2
      } else {
        shootingStarsRef.current.position.x = Math.random() * 50 + 90
        shootingStarsRef.current.position.z = Math.random() * 0 - 20
      }
    }
  })

  return (
    <group ref={shootingStarsRef}>
      {shootingStars.map(({ position, size }, index) => (
        <Float floatIntensity={10} key={index}>
          <mesh key={index} position={position} scale={[size, size, size]}>
            <Sphere args={[1.5, 20, 20]}>
              <meshStandardMaterial
                map={terrainTexture.map}
                roughnessMap={terrainTexture.rough}
                displacementMap={terrainTexture.disp}
                displacementScale={0.05}
              />
            </Sphere>
          </mesh>
        </Float>
      ))}
    </group>
  )
}

export default ShootingStars
