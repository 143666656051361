import { FC } from 'react'
import { useSelector } from 'react-redux'
import { DarkModeIcon, LightModeIcon } from '../SVG'
import './css/ToggleButton.css'

type Props = {
  handleTheme?: () => void
  theme: 'LIGHT' | 'DARK'
}
const ToggleButton: FC<Props> = ({ handleTheme, theme }) => {
  const { width, scroll } = useSelector((state: any) => state.app)

  //Mobil-skjerm: toggle ligger inni CV på mobil
  const hideToggle = width < 5102
  const opacity = 1 - scroll / 100
  const removeToggle = opacity < 0.09 ? 'none' : 'flex'
  return (
    <>
      <div
        // For å opptå plassen til toggleContainer om den har display:none
        style={{ width: '30%', display: opacity < 0.09 ? 'flex' : 'none' }}
      />
      {theme === 'LIGHT' ? (
        <div
          className="toggleContainer"
          style={{
            opacity: hideToggle ? opacity : 1,
            display: hideToggle ? removeToggle : 'flex',
          }}
        >
          <button className="toggleBtnDark" onClick={handleTheme}>
            <div className="iconAndBtn">
              <div className="toggleIcon">
                <LightModeIcon color="#ffff00" />
              </div>
              <p className="modeText">Dark</p>
            </div>
          </button>
        </div>
      ) : (
        <div
          className="toggleContainer"
          style={{
            opacity: hideToggle ? opacity : 1,
            display: hideToggle ? removeToggle : 'flex',
          }}
        >
          <button className="toggleBtn" onClick={handleTheme}>
            <div className="iconAndBtn">
              <p className="modeText modeTextDark">Light</p>
              <div className="toggleIcon">
                <DarkModeIcon color={'#ececec'} />
              </div>
            </div>
          </button>
        </div>
      )}
    </>
  )
}

export default ToggleButton
