const CV = (width: number) => {
  let height = 0

  switch (true) {
    case width <= 510:
      height = 3100
      break
    case width > 510 && width < 767:
      height = 3100
      break
    case width > 767 && width < 1024:
      height = 2600
      break
    case width > 1024:
      height = 2400
      break
    default:
      height = 3800
      break
  }

  return height
}
const EXPERTISE = (width: number) => {
  let height = 0

  switch (true) {
    case width <= 510:
      height = 4450
      break
    case width > 510 && width < 1024:
      height = 3600
      break
    case width > 1024:
      height = 3700
      break

    default:
      height = 3800
      break
  }

  return height
}
const ABOUT = (width: number) => {
  let height = 0

  switch (true) {
    case width <= 620:
      height = 4200
      break
    case width > 510 && width < 1024:
      height = 2200
      break
    case width > 1024:
      height = 1600
      break
    default:
      height = 3800
      break
  }

  return height
}

export const getScreenHeight = (site: string, width: number) => {
  let height = 0

  switch (site) {
    case 'CV':
      height = CV(width)
      break
    case 'EXPERTISE':
      height = EXPERTISE(width)
      break
    case 'ABOUT':
      height = ABOUT(width)
      break
    default:
      height = 3800
      break
  }

  return height
}
