export const LightModeIcon = ({ color }: { color: string }) => (
  <svg height="40" viewBox="0 -960 960 960" width="40" fill={color}>
    <path d="M480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM73.333-446.667q-14.166 0-23.75-9.617Q40-465.901 40-480.117q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h93.334q14.166 0 23.75 9.617Q200-494.099 200-479.883q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H73.333Zm720 0q-14.166 0-23.75-9.617Q760-465.901 760-480.117q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h93.334q14.166 0 23.75 9.617Q920-494.099 920-479.883q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5h-93.334ZM479.883-760q-14.216 0-23.716-9.583-9.5-9.584-9.5-23.75v-93.334q0-14.166 9.617-23.75Q465.901-920 480.117-920q14.216 0 23.716 9.583 9.5 9.584 9.5 23.75v93.334q0 14.166-9.617 23.75Q494.099-760 479.883-760Zm0 720q-14.216 0-23.716-9.583-9.5-9.584-9.5-23.75v-93.334q0-14.166 9.617-23.75Q465.901-200 480.117-200q14.216 0 23.716 9.583 9.5 9.584 9.5 23.75v93.334q0 14.166-9.617 23.75Q494.099-40 479.883-40ZM235.334-678 183-729.334q-10-9.666-9.586-23.735.414-14.069 9.517-24 9.931-9.931 24-9.931t23.735 10L282-724.666q9 10 9 23.333 0 13.333-9 23-9 9.666-22.833 9.5Q245.334-669 235.334-678Zm494 495L678-235.334q-9-10-9-23.749 0-13.75 9.333-22.917 9.334-10 22.834-9.833 13.499.166 23.499 9.833L777-230.666q10 9.666 9.586 23.735-.414 14.069-9.517 24-9.931 9.931-24 9.931t-23.735-10ZM678-678q-10-9.667-9.833-23.167.166-13.499 9.833-23.499L729.334-777q9.666-10 23.735-9.586 14.069.414 24 9.517 9.931 9.931 9.931 24t-10 23.735L724.666-678q-9.333 9-22.909 9-13.575 0-23.757-9ZM182.931-182.931q-9.931-9.931-9.931-24t10-23.735L235.334-282q9.866-9.667 23.433-9.667 13.566 0 22.972 9.667 10.261 9.667 10.094 23.167-.166 13.499-9.833 23.499L230.666-183q-9.666 10-23.735 9.586-14.069-.414-24-9.517Z" />
  </svg>
)
export const DarkModeIcon = ({ color }: { color: string }) => (
  <svg height="40" viewBox="0 -960 960 960" width="40" fill={color}>
    <path d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q10 0 20.5.667 10.5.666 24.166 2-37.666 31-59.166 77.833T444-660q0 90 63 153t153 63q53 0 99.667-20.5 46.666-20.5 77.666-56.166 1.334 12.333 2 21.833.667 9.5.667 18.833 0 150-105 255T480-120Z" />
  </svg>
)
export const MailIcon = ({ color }: { color: string }) => (
  <svg height="24" viewBox="0 -960 960 960" width="24" fill={color}>
    <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
  </svg>
)
export const PhoneIcon = ({ color }: { color: string }) => (
  <svg height="24" viewBox="0 -960 960 960" width="24" fill={color}>
    <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
  </svg>
)
export const ScrollIcon = ({ color }: { color: string }) => (
  <svg height="48" viewBox="0 -960 960 960" width="48" fill={color}>
    <path d="M133.124-394Q97-394 72-418.876t-25-61Q47-516 71.876-541t61-25Q169-566 194-541.124t25 61Q219-444 194.124-419t-61 25ZM133-446q14.385 0 24.192-9.808Q167-465.615 167-480t-9.808-24.192Q147.385-514 133-514q-15.385 0-24.692 9.808Q99-494.385 99-480t9.308 24.192Q117.615-446 133-446Zm231.124 52Q328-394 303.5-418.876t-24.5-61Q279-516 303.376-541t60.5-25Q400-566 425-541.124t25 61Q450-444 425.124-419t-61 25ZM364-445q15.385 0 25.692-9.808Q400-464.615 400-480t-10.308-25.692Q379.385-516 364-516t-25.192 10.308Q329-495.385 329-480t9.596 25.192Q348.192-445 364-445Zm232.124 51Q560-394 535-418.876t-25-61Q510-516 534.876-541t61-25Q632-566 656.5-541.124t24.5 61Q681-444 656.624-419t-60.5 25Zm.876-51q15.385 0 25.192-9.808Q632-464.615 632-480t-9.808-25.692Q612.385-516 597-516t-25.192 10.308Q562-495.385 562-480t9.596 25.192Q581.192-445 597-445Zm230.124 51Q791-394 766-418.876t-25-61Q741-516 765.876-541t61-25Q863-566 888-541.124t25 61Q913-444 888.124-419t-61 25Z" />
  </svg>
)
export const ArrowDownIcon = ({ color }: { color: string }) => (
  <svg height="24" viewBox="0 -960 960 960" width="24" fill={color}>
    <path d="M480-80 200-360l56-56 184 183v-647h80v647l184-184 56 57L480-80Z" />
  </svg>
)

export const DownloadIcon = ({
  color,
  className,
}: {
  color?: string
  className: string
}) => (
  <svg
    height="40"
    viewBox="0 -960 960 960"
    width="40"
    fill={color}
    className={className}
  >
    <path d="M480-315.333 284.667-510.666l47.333-48 114.667 114.667V-800h66.666v356.001L628-558.666l47.333 48L480-315.333ZM226.666-160q-27 0-46.833-19.833T160-226.666V-362h66.666v135.334h506.668V-362H800v135.334q0 27-19.833 46.833T733.334-160H226.666Z" />
  </svg>
)
