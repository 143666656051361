import { InformationType } from './InformationTypes'
import { v4 as uuidv4 } from 'uuid'

export const Information: InformationType = {
  profilePointers: [
    {
      id: uuidv4(),
      pointer: '34 år',
      delay: 50,
      startDelay: 0,
    },
    {
      id: uuidv4(),
      pointer: 'Bor i Oslo',
      delay: 50,
      startDelay: 900,
    },
    {
      id: uuidv4(),
      pointer: 'Målbevisst',
      delay: 50,
      startDelay: 1800,
    },
    {
      id: uuidv4(),
      pointer: 'Positiv',
      delay: 50,
      startDelay: 2500,
    },
    {
      id: uuidv4(),
      pointer: 'Omgjengelig',
      delay: 50,
      startDelay: 3100,
    },
    {
      id: uuidv4(),
      pointer: 'Sporty',
      delay: 50,
      startDelay: 3700,
    },
  ],
  workExperience: [
    {
      id: uuidv4(),
      experience: 'WOIT',
      date: { start: 2022, end: 'dd' },
      description:
        'Fagansvarlig og frontend-utvikler for trenings-appen Woit. Appen er kodet i React Native, og benytter AI-teknologi for å gjøre det enklere å nå treningsmål. Den lager program, gir deg feedback basert på sensor-målinger og holder styr på trenings-historikken din.',
    },
    {
      id: uuidv4(),
      experience: 'FITR',
      date: { start: 2020, end: 'dd' },
      description:
        'Utvikler trenings-app i React Native som hjelper brukeren med å lage program, lage egne øvelser, logge treninger og se statistikk.',
    },
    {
      id: uuidv4(),
      experience: 'Personlig Trener Evo Oscarsgate',
      date: { start: 2017, end: 'dd' },
      description:
        'Som selvstendig næringsdrivende under treningskjeden Evo har jeg måttet ta kontroll på økonomi (fakturering og innbetalinger), årsoppgjør, organisering av egen arbeidsdag og analysering av kundens behov, ønsker og fremgang for så å tilpasse deretter. ',
    },
    {
      id: uuidv4(),
      experience: 'Brukerstyrt Personlig Assistent',
      date: { start: 2015, end: 2021 },
      description:
        'Jobbet spesifikt hos en mann med progressiv MS i hans siste tid. Assistentene organiserte alt av møter som sykehusbesøk, legetimer og fysioterapi. Sørget også for utlevering av medisiner og mer avanserte ting som EKG-måler og slimsuger.',
    },
    {
      id: uuidv4(),
      experience: 'SATS Bislett',
      date: { start: 2014, end: 2017 },
      description:
        'Hos Sats jobbet vi i team med klare mål for antall leverte PT-timer på individuelt og samlet nivå. Vi hadde egne kurs innad i teamet hvor vi delte erfaringer og spesialiteter.',
    },
    {
      id: uuidv4(),
      experience: 'SATS Drammen',
      date: { start: 2011, end: 2014 },
      description:
        'Jobbet tett på medlemmer for å sikre at de fikk en god treningsopplevelse. Besvarte henvendelser via telefon og e-post. Håndterte betalinger og transaksjoner for medlemmer og besøkende og tok oppgjøret ved avslutning. ',
    },
    {
      id: uuidv4(),
      experience: 'Statoil',
      date: { start: 2009, end: 2011 },
      description:
        'Jobbet med kundeservice, salg av produkter og tjenester, samtidig som jeg opprettholdt et ryddig og trygt miljø på stasjonen.',
    },
  ],
  education: [
    {
      id: uuidv4(),
      experience: 'Safe Education / Personlig Trener',
      date: { start: 2013, end: 2014 },
    },
    {
      id: uuidv4(),
      experience: 'BI / Eiendomsmegling',
      description: '- uten endelig eksamen',
      date: { start: 2010, end: 2013 },
    },
    {
      id: uuidv4(),
      experience: 'Drammen Videregående skole',
      description: 'Toppidrett',
      date: { start: 2005, end: 2008 },
    },
    {
      id: uuidv4(),
      experience: 'Åssiden Videregående skole',
      description: 'Byggfag',
      date: { start: 2004, end: 2005 },
    },
  ],
  courses: [
    {
      id: uuidv4(),
      course:
        'The Complete Javascript Course 2023:From Zero To Expert, Jonas Schmedtmann',
    },
    {
      id: uuidv4(),
      course: 'The Complete 2023 Web Development Bootcamp, Angela Yu',
    },
    {
      id: uuidv4(),
      course: 'Three.js journey, Bruno Simon',
    },
  ],
  reactTechStack: [
    {
      id: uuidv4(),
      title: 'REACT',
    },
    {
      id: uuidv4(),
      title: 'TYPESCRIPT',
    },
    {
      id: uuidv4(),
      title: 'SSR / SPR',
    },
    {
      id: uuidv4(),
      title: 'NEXT.JS',
    },
    {
      id: uuidv4(),
      title: 'STATE HANDLING',
      subtitle: ['Redux Toolkit', 'Redux Persist', 'Redux Saga'],
    },
  ],
  nativeTechStack: [
    {
      id: uuidv4(),
      title: 'Typescript',
    },
    {
      id: uuidv4(),
      title: 'React Navigation',
    },
    {
      id: uuidv4(),
      title: 'Reanimated',
    },
    {
      id: uuidv4(),
      title: 'React Native Skia',
    },
    {
      id: uuidv4(),
      title: 'Redux',
    },
  ],
  deputyMember: [
    {
      id: uuidv4(),
      experience: 'Styreverv Stilkrosen Boligsameie(vara)',
      date: { start: 2023, end: 'dd' },
    },
  ],
  application: {
    experience:
      "SØKNAD: Min interesse for koding begynte i 2021, og siden har jeg ikke gjort annet. Det begynte selvsagt med mange små prosjekter som stoppet seg selv da jeg ble bedre til å strukturere koden. Neste prosjekt fikk en bedre struktur og ble generelt mer avansert. Jeg var så heldig at jeg fikk være med på ett prosjekt i React Native som virkelig økte mine evner innen utvikling. Oppgavene lå klart i Trello, deling av kode skjedde via GIT, og jeg fikk innsikt i 'best practice'. Jeg har stor interesse for koding og er nysgjerrig på hva mer jeg kan lære.",
  },
  expertise: [
    {
      id: uuidv4(),
      expertise: 'HTML / CSS / JS',
    },
    {
      id: uuidv4(),
      expertise: 'TYPESCRIPT',
    },
    {
      id: uuidv4(),
      expertise: 'REACT',
    },
    {
      id: uuidv4(),
      expertise: 'REACT NATIVE',
    },
    {
      id: uuidv4(),
      expertise: 'REACT THREE FIBER',
    },
    {
      id: uuidv4(),
      expertise: 'GIT',
    },
  ],
  language: [
    {
      id: uuidv4(),
      language: 'Norsk',
      subtitle: 'skriftlig / muntlig',
    },
    {
      id: uuidv4(),
      language: 'Engelsk',
      subtitle: 'skriftlig / muntlig',
    },
    {
      id: uuidv4(),
      language: 'Spansk',
      subtitle: 'basic',
    },
  ],
  aboutInfo: {
    aboutMe: {
      header: 'Hvem er jeg?',
      description: `Jeg ser på meg selv som ressurssterk og eventyrlysten med et sterkt ønske om å utforske og lære nye ting. Med egenskaper som en positiv og inkluderende person, trives jeg i ulike sosiale settinger. Min åpenhet for andres synspunkter og væremåter gjør at jeg enkelt kan etablere gode relasjoner med mennesker. En aktiv livsstil har gitt meg verdifulle erfaringer som har formet min evne til å møte utfordringer med entusiasme og vedvarende innsats.. 
      
       
      Som en person som verdsetter personlig og faglig utvikling, er jeg alltid åpen for å lære og forbedre meg. En kontinuerlig utvidelse av egne ferdigheter og kunnskaper er en forutsetning for  min personlige og faglige vekst. Min dedikasjon ligger i å bidra positivt til teamet og organisasjonen jeg engasjerer meg i, og dette er kanskje min viktigste driver for å utvikle meg ytterligere.`,
    },
    activities: {
      header: 'Aktiviteter',
      description:
        'Gjennom livet har jeg funnet stor glede i idrett. Jeg har holdt på med masse aktiviteter, og mine hovedinteresser har vært turn, sykkel, parkour og ski. Før jeg valgte å gjøre min lidenskap for idrett til min profesjon som personlig trener, oppnådde jeg noen meritter, inkludert syv ganger som norgesmester i turn.',
    },
    travel: {
      header: 'Reise',
      description:
        'Reising er noe jeg setter høyt opp på lista over gode erfaringer. Jeg liker å oppleve ulike kulturer, smake nye matretter, og knytte vennskap på tvers av landegrenser.',
    },
    bike: {
      header: 'Sykkel',
      description:
        'Jeg har noen tilbakelagte mil på sykkel. Min første ordentlige tur var Norge på langs med min storesøster. Med lite erfaring før avreise, ble det en tøff, men bra opplevelse. Siden har jeg syklet fra nord i Danmark til sør i Spania. Min siste tur var Oslo-Nederland tur retur for å besøke en venn.',
    },
  },
}
