import React from 'react'
import { Information } from '../staticInfo/Information'
import { ExpertiseType, LanguageType } from '../types/types'
import { getThemeColors } from '../utils/ThemeUtils'

const LanguageAndExpertise = () => {
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  return (
    <>
      <div
        className="columnHeaders leftHeader"
        style={{ backgroundColor: themeColor.background }}
      >
        <h2
          className="cvHeaders"
          style={{
            color: themeColor.main,
          }}
        >
          SPRÅK
        </h2>
      </div>
      <div className="pointersContainer">
        {Information.language.map((l: LanguageType) => (
          <React.Fragment key={l.id}>
            <p className="pointers">・ {l.language}</p>
            <p
              className="pointers"
              style={{ marginLeft: 20, marginBottom: 15 }}
            >
              {l.subtitle}
            </p>
          </React.Fragment>
        ))}
      </div>
      <div
        className="columnHeaders leftHeader"
        style={{ backgroundColor: themeColor.background }}
      >
        <h2
          className="cvHeaders"
          style={{
            color: themeColor.main,
          }}
        >
          EKSPERTISE
        </h2>
      </div>
      <div className="pointersContainer">
        {Information.expertise.map((e: ExpertiseType) => (
          <p className="pointers" key={e.id}>
            ・ {e.expertise}
          </p>
        ))}
      </div>
    </>
  )
}

export default LanguageAndExpertise
