import '../components/css/About.css'
import { useSelector } from 'react-redux'
import { getThemeColors } from '../components/utils/ThemeUtils'
import ImageAndTxtSelector from '../components/ImageAndTxtSelector'

const About = () => {
  const width = useSelector((state: any) => state.app.width)
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const tryvannPoster = require('../pictures/about/tryvannPoster.jpg')
  const tryvannMovie = require('../pictures/about/tryvann.MP4')
  const { images, txtBox } = ImageAndTxtSelector()

  return (
    <>
      <div className="main" style={{ marginTop: 120 }}>
        <div
          className={width <= 960 ? 'tall' : 'short'}
          style={{ ...themeColor.aboutShort }}
        >
          <h4 style={{ color: themeColor.text }}>
            {txtBox.firstTxtBox.header}
          </h4>
          <p style={{ color: themeColor.text, whiteSpace: 'pre-line' }}>
            {txtBox.firstTxtBox.description}
          </p>
        </div>
        <div
          className="tall"
          style={{ ...themeColor.aboutTall, overflow: 'hidden' }}
        >
          <img src={images.firstImage.src} alt={images.firstImage.alt} />
        </div>
        <div className="short" style={{ ...themeColor.aboutShort }}>
          <h4 style={{ color: themeColor.text }}>
            {txtBox.secondTxtBox.header}
          </h4>
          <p style={{ color: themeColor.text }}>
            {txtBox.secondTxtBox.description}
          </p>
        </div>

        <div
          className="tall"
          style={{ ...themeColor.aboutTall, overflow: 'hidden' }}
        >
          <img src={images.secondImage.src} alt={images.secondImage.alt} />
        </div>
        <div
          className="tall"
          style={{ ...themeColor.aboutTall, overflow: 'hidden' }}
        >
          <img src={images.thirdImage.src} alt={images.thirdImage.alt} />
        </div>
        <div
          className={width <= 960 ? 'short' : 'tall'}
          style={{ ...themeColor.aboutTall }}
        >
          <h4 style={{ color: themeColor.text }}>
            {txtBox.thirdTxtBox.header}
          </h4>
          <p style={{ color: themeColor.text, whiteSpace: 'pre-line' }}>
            {txtBox.thirdTxtBox.description}
          </p>
        </div>
        <div
          className="tall"
          style={{ ...themeColor.aboutTall, overflow: 'hidden' }}
        >
          <img src={images.fourthImage.src} alt={images.firstImage.alt} />
        </div>
        {width <= 620 ? (
          <>
            <div className="short" style={{ ...themeColor.aboutShort }}>
              <h4 style={{ color: themeColor.text }}>
                {txtBox.fourthTxtBox.header}
              </h4>
              <p style={{ color: themeColor.text }}>
                {txtBox.fourthTxtBox.description}
              </p>
            </div>
            <div className="tall" style={{ ...themeColor.aboutTall }}>
              <div
                className="video-container"
                style={{ ...themeColor.aboutTall }}
              >
                <video
                  className="video-element"
                  muted
                  loop
                  controls={width <= 510 ? true : false}
                  autoPlay={width <= 510 ? false : true}
                  poster={tryvannPoster}
                >
                  <source src={tryvannMovie} type="video/mp4" />
                </video>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tall" style={{ ...themeColor.aboutTall }}>
              <div
                className="video-container"
                style={{ ...themeColor.aboutTall }}
              >
                <video
                  className="video-element"
                  muted
                  loop
                  controls={width <= 510 ? true : false}
                  autoPlay={width <= 510 ? false : true}
                  poster={tryvannPoster}
                >
                  <source src={tryvannMovie} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="short" style={{ ...themeColor.aboutShort }}>
              <h4 style={{ color: themeColor.text }}>
                {txtBox.fourthTxtBox.header}
              </h4>
              <p style={{ color: themeColor.text }}>
                {txtBox.fourthTxtBox.description}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default About
