import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'

useGLTF.preload('/comp_poles.glb')

const CompPoles = ({
  smallScreen,
  material,
}: {
  smallScreen: boolean
  material: any
}) => {
  // @ts-ignore
  const { nodes } = useGLTF('/comp_poles.glb')

  const pointerRef = useRef<any>(null)

  const southMaterial = new THREE.MeshBasicMaterial({
    color: '#b10000',
  })
  const northMaterial = new THREE.MeshBasicMaterial({ color: '#0000b1' })

  const pointerMaterial = new THREE.MeshStandardMaterial({
    metalness: 1,
    roughness: 0.35,
  })
  useFrame(({ pointer, clock }) => {
    if (smallScreen) {
      const elapsedTime = clock.elapsedTime
      if (!pointerRef.current.startTime) {
        pointerRef.current.startTime = elapsedTime
        pointerRef.current.duration = Math.random() * 1 + 1 // Tilfeldig varighet mellom 1 og 4 sekunder
        pointerRef.current.targetRotation =
          pointerRef.current.rotation.y +
          (Math.random() * 90 - 45) * (Math.PI / 180) // Tilfeldig rotasjonsmål, +/- 25 grader
      }

      const deltaTime = elapsedTime - pointerRef.current.startTime
      const progress = Math.min(deltaTime / pointerRef.current.duration, 1) // Sørger for at progressen ikke overstiger 1

      pointerRef.current.rotation.y +=
        (pointerRef.current.targetRotation - pointerRef.current.rotation.y) *
        0.008 // Juster rotasjonen gradvis mot målet

      if (progress === 1) {
        pointerRef.current.startTime = null // Tilbakestill starttiden for å starte på nytt med neste tilfeldige rotasjon
      }
    } else {
      // Beregn vinkelen mellom musens x-posisjon og kryssets x-posisjon
      const deltaX =
        pointer.x * window.innerWidth - pointerRef.current.position.x
      const deltaY =
        pointer.y * window.innerHeight - pointerRef.current.position.y
      const angle = Math.atan2(deltaY, deltaX)
      // Oppdater rotasjonen til krysset basert på den beregnede vinkelen
      pointerRef.current.rotation.y = -angle - Math.PI / 2
    }
  })

  const centerMesh = new THREE.DodecahedronGeometry(0.08, 5)

  return (
    <>
      <mesh geometry={centerMesh} position={[0, 0, 0.18]} material={material} />
      <group
        ref={pointerRef}
        scale={0.7}
        dispose={null}
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, 0, 0.18]}
        castShadow
        receiveShadow
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.north_west.geometry}
          material={pointerMaterial}
          rotation={[0, Math.PI / 4, 0]}
          scale={0.152}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.north_pole.geometry}
          material={pointerMaterial}
          rotation={[0, 0.004, 0]}
          scale={0.227}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.east_pole.geometry}
          material={pointerMaterial}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.227}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.west_pole.geometry}
          material={pointerMaterial}
          rotation={[0, 1.568, 0]}
          scale={0.227}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.north_est.geometry}
          material={pointerMaterial}
          rotation={[0, -0.785, 0]}
          scale={0.152}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.south_east.geometry}
          material={pointerMaterial}
          rotation={[Math.PI, -0.793, Math.PI]}
          scale={0.152}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.south_west.geometry}
          material={pointerMaterial}
          rotation={[-Math.PI, 0.781, -Math.PI]}
          scale={0.152}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.north_pole_tip.geometry}
          material={northMaterial}
          rotation={[0, 0.004, 0]}
          scale={0.227}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.south_pole.geometry}
          material={pointerMaterial}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0.227}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.south_pole_tip.geometry}
          material={southMaterial}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0.227}
        />
      </group>
    </>
  )
}

export default CompPoles
