import './css/NavigationBar.css'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ToggleButton from './ToggleButton'
import { useSelector } from 'react-redux'
import NavigationButton from './NavigationButton'
import PhoneNavigationButton from './PhoneNavigationButton'

export type ScreenIds = 'CV' | 'EXPERTISE' | 'ABOUT'

type ToggleProps = {
  handleTheme?: () => void
}
const NavigationBar: FC<ToggleProps> = ({ handleTheme }) => {
  const [currentPage, setCurrentPage] = useState<ScreenIds>('EXPERTISE')
  const [scrolling, setScrolling] = useState(false)
  const { width } = useSelector((state: any) => state.app)
  const navigate = useNavigate()
  const localStorageTheme = localStorage.getItem('theme') || 'LIGHT'

  const pathMap: Record<ScreenIds, string> = {
    EXPERTISE: '/',
    ABOUT: '/about',
    CV: '/cv',
  }

  const classNameNavContainer =
    localStorageTheme === 'LIGHT'
      ? `navigationBar ${scrolling ? 'scrolled' : 'scrolledOut'}`
      : `navigationBar ${scrolling ? 'scrolledDark' : 'scrolledOut'}`

  useEffect(() => {
    navigate(pathMap[currentPage])

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }
    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', handleScroll)

    scrollToTop()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [currentPage])

  return (
    <div className="navigationContainer">
      <ToggleButton
        handleTheme={handleTheme}
        theme={localStorageTheme as 'LIGHT' | 'DARK'}
      />

      <div className="navContainerBoth">
        {width > 510 ? (
          <div className={classNameNavContainer}>
            <NavigationButton
              title="EKSPERTISE"
              id="EXPERTISE"
              path="/"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <NavigationButton
              title="OM MEG"
              id="ABOUT"
              path="/about"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <NavigationButton
              title="CV"
              id="CV"
              path="/cv"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : (
          <div
            className={classNameNavContainer}
            style={{ maxWidth: 510, height: 50 }}
          >
            <PhoneNavigationButton
              title="EKSPERTISE"
              id="EXPERTISE"
              path="/"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <PhoneNavigationButton
              title="OM MEG"
              id="ABOUT"
              path="/about"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <PhoneNavigationButton
              title="CV"
              id="CV"
              path="/cv"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default NavigationBar
