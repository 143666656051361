import ReactPresentation from '../components/ReactPresentation'
import ThreeFiberPresentation from '../components/ThreeFiberPresentation'
import WorkoutApp from '../components/WorkoutApp'

const Projects = () => {
  return (
    <div
      style={{
        paddingTop: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <ReactPresentation />
      <ThreeFiberPresentation />
      <WorkoutApp />
    </div>
  )
}

export default Projects
