import './css/ReactPresentation.css'
import { Canvas } from '@react-three/fiber'
import MacReact from '../models/MacReact'
import { getThemeColors } from './utils/ThemeUtils'
import Typewriter from './Typewriter'
import { Information } from './staticInfo/Information'
import { ReactTechType } from './types/types'
import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import Iphone from '../models/Iphone'
import { ScrollIcon } from '../SVG'
import useTypeWriterStyles from './utils/hooks/TypeWriterStyles'
import useSubTitleStyles from './utils/hooks/SubTitleStyles'

const ReactPresentation = () => {
  const { width, scroll } = useSelector((state: any) => state.app)
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const startAnimation = scroll >= 1 ? true : false
  const typeWriterStyle = useTypeWriterStyles(width)
  const subtitleStyle = useSubTitleStyles(width)
  const [iphoneVisible, setIphoneVisible] = useState(false)
  const responsiveLeft = ResponsiveStyles(width)
  const showScrollIllustration = scroll === 0 ? true : false

  let cameraDistance = 7
  if (width <= 620) {
    cameraDistance = 10
  } else if (width <= 1024) {
    cameraDistance = 7
  } else if (width > 1900) {
    cameraDistance = 5
  }

  return (
    <>
      {width <= 510 && (
        <>
          {showScrollIllustration && <p className="scrollPointer">👆🏼</p>}
          <div
            style={{
              position: 'absolute',
              rotate: '90deg',
              top: '67%',
              marginLeft: -10,
            }}
          >
            <ScrollIcon color={theme === 'LIGHT' ? '#151515' : '#ededed'} />
          </div>
        </>
      )}
      <div
        style={{
          position: 'absolute',
          top: 250,
          width: 110,
          zIndex: 3,
          backgroundColor: 'transparent',
          height: '100vh',
        }}
      />

      <Typewriter
        delay={50}
        startDelay={1000}
        text={'REACT'}
        className={'reactHeader'}
        style={{ ...typeWriterStyle, top: 200 }}
      />

      <div className="reactDescriptContainer">
        {width <= 1024 && (
          <MacBtn
            setIphoneVisible={setIphoneVisible}
            iphoneVisible={iphoneVisible}
          />
        )}
        {width > 1024 && (
          <button
            onClick={() => {
              setIphoneVisible(!iphoneVisible)
            }}
            className="deviceBtn"
            style={{
              color: themeColor.projectText,
              cursor: 'pointer',
            }}
          >
            Bytt enhet
          </button>
        )}

        {Information.reactTechStack.map((react: ReactTechType) => (
          <React.Fragment key={react.id}>
            <p
              className="reactDescription"
              style={{
                color: themeColor.projectText,
                marginBottom: react.subtitle ? '30px' : '40px',
                ...subtitleStyle,
              }}
            >
              {react.title}
            </p>
            {react.subtitle?.map((sub) => (
              <p
                key={sub}
                className="reactDescription"
                style={{
                  color: themeColor.projectText,
                  marginTop: '-20px',
                  ...subtitleStyle,
                }}
              >
                -{sub}
              </p>
            ))}
          </React.Fragment>
        ))}
      </div>
      <Canvas
        style={{
          height: 800,
          position: 'relative',
          zIndex: 2,
          left: responsiveLeft,
          touchAction: 'none',
          top: width > 1024 ? 100 : 0,
        }}
        shadows
        camera={{
          fov: 45,
          near: 0.1,
          far: 200,
          position: [-2, -0.5, 7],
        }}
      >
        {iphoneVisible ? (
          <Iphone
            startAnimation={startAnimation}
            cameraDistance={width <= 620 ? cameraDistance - 2 : cameraDistance}
          />
        ) : (
          <MacReact
            startAnimation={startAnimation}
            cameraDistance={cameraDistance}
          />
        )}
      </Canvas>
      {width > 1024 && (
        <MacBtn
          setIphoneVisible={setIphoneVisible}
          iphoneVisible={iphoneVisible}
        />
      )}
    </>
  )
}

export default ReactPresentation

const ResponsiveStyles = (width: number) => {
  let responsiveLeft = 0
  switch (true) {
    case width > 2000:
      responsiveLeft = -480
      break
    case width > 1600:
      responsiveLeft = -380
      break
    case width > 1024:
      responsiveLeft = -200
      break
  }

  return responsiveLeft
}

const MacBtn = ({
  setIphoneVisible,
  iphoneVisible,
}: {
  setIphoneVisible: (b: boolean) => void
  iphoneVisible: boolean
}) => {
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const { width } = useSelector((state: any) => state.app)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <button
        className="deviceBtnWebLink"
        style={{
          color: themeColor.projectText,
          cursor: 'pointer',
          alignSelf: width > 1024 ? 'center' : 'auto',
        }}
      >
        <a
          href="https://www.endringslederne.no"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            zIndex: 1000,
            color: themeColor.projectText,
            fontSize: 14,
            textTransform: 'none',
            cursor: 'pointer',
            textAlign: 'center',
          }}
        >
          Besøk Endringslederne
        </a>
      </button>
      {width <= 1024 && (
        <>
          <div style={{ height: 3 }} />
          <button
            onClick={() => {
              setIphoneVisible(!iphoneVisible)
            }}
            className="deviceBtn"
            style={{
              color: themeColor.projectText,
              cursor: 'pointer',

              fontSize: 14,
            }}
          >
            Bytt enhet
          </button>
        </>
      )}
    </div>
  )
}
