import Compass from '../models/compass'
import ReactThreeCanvas from './ReactThreeCanvas'

const CompassSectionOnSmallDevices = () => {
  const COMPASS_STYLE_SMALL = {
    height: 400,
    // width: width === null ? 400 : width - 100,
    width: -100,
    zIndex: 0, // BEHOLD DENNE(PGA NAVBAR)
    touchAction: 'none',
  }
  return (
    <>
      <div
        style={{
          marginBottom: 150,
        }}
      >
        <ReactThreeCanvas
          style={COMPASS_STYLE_SMALL}
          cameraPosition={[0, 0, -6] as [number, number, number]}
        >
          <Compass
            position={[0, -0.5, -1.7]}
            // isVisible={headerFade.opacity === 1}
            isVisible={true}
          />
        </ReactThreeCanvas>
      </div>
    </>
  )
}
export default CompassSectionOnSmallDevices
