'use client'
import React, { FC, useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { PointLightHelper } from 'three'

type lightProps = {
  meshPosition: [number, number, number]
}

const SpotlightCirclingMesh: FC<lightProps> = ({ meshPosition }) => {
  const spotlightRef = useRef<any>(null)
  const pointLightHelperRef = useRef<PointLightHelper>(null)

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime()
    const radius = 2.5
    const speed = 0.35

    if (spotlightRef.current !== null) {
      spotlightRef.current.position.x =
        meshPosition[0] + Math.cos(time * speed) * radius
      spotlightRef.current.position.y =
        meshPosition[1] + Math.sin(time * speed) * radius
      spotlightRef.current.position.z = -0.5

      spotlightRef.current.lookAt(
        meshPosition[0],
        meshPosition[1] - 1,
        meshPosition[2]
      ) // Retter lyset mot meshen
      if (pointLightHelperRef.current) {
        pointLightHelperRef.current.position.copy(spotlightRef.current.position)
      }
    }
  })

  return (
    <>
      <spotLight ref={spotlightRef} intensity={8} position={[0, 0, -1]} />
      {/* <pointLight intensity={0.07} position={[0, -0.1, 0.9]} /> */}
      <pointLight intensity={0.8} position={[0, -0.07, -3]} />
      {/* {spotlightRef.current && (
        <pointLightHelper
          ref={pointLightHelperRef}
          args={[spotlightRef.current, 0.7]} // Angi spotlyset og størrelsen på hjelperen
        />
      )} */}
    </>
  )
}

export default SpotlightCirclingMesh
