import DownloadPdf from './cv/DownloadPdf'
import { getThemeColors } from './utils/ThemeUtils'
import './css/Cv.css'
import { FC } from 'react'
import { Information } from './staticInfo/Information'
import ExperienceSection from './cv/ExperienceSection'
import { CoursesType } from './types/types'
import { useSelector } from 'react-redux'

const RightCvColumn = () => {
  const { width } = useSelector((state: any) => state.app)

  return (
    <div className="columnContainer rightC">
      <div className="rightCvInfo">
        <ExperienceSection
          array={Information.workExperience}
          header={'ARBEIDSERFARING'}
        />
        <ExperienceSection array={Information.education} header={'UTDANNING'} />
        <ColumnHeader header={'KURS'} />

        <div className="cvCourses">
          {Information.courses.map((c: CoursesType) => {
            return (
              <p key={c.id} style={{ fontWeight: 600 }}>
                {c.course}
              </p>
            )
          })}
        </div>
        {/* <ColumnHeader header={'SKILLS OPPSUMMERING'} />
        <SkillsSummary title={'REACT'} percent={70} />
        <SkillsSummary title={'REACT NATIVE'} percent={80} />
        <SkillsSummary title={'REACT THREE FIBER'} percent={20} /> */}
        <ExperienceSection array={Information.deputyMember} header={'VERV'} />
      </div>
      {width > 510 && <DownloadPdf />}
    </div>
  )
}

export default RightCvColumn

type Props = {
  header: string
}
const ColumnHeader: FC<Props> = ({ header }) => {
  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')

  return (
    <div
      className="columnHeaders"
      style={{ backgroundColor: themeColor.background }}
    >
      <h2
        className="cvHeaders"
        style={{
          color: themeColor.main,
        }}
      >
        {header}
      </h2>
    </div>
  )
}
