import './App.css'
import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import NavigationBar from './components/NavigationBar'
import About from './screens/About'
import Projects from './screens/Projects'
import Cv from './screens/Cv'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setScroll, setWidth } from './Redux/reducers/AppReducer'
import { getScreenHeight } from './components/utils/hooks/ScreenHeight'
import Lenis from '@studio-freight/lenis'

function App() {
  const dispatch = useDispatch()
  const [reRender, setReRender] = useState(false)
  const localStorageTheme = localStorage.getItem('theme') || 'LIGHT'
  const { width, currentScreen } = useSelector((state: any) => state.app)
  const screenHeight = getScreenHeight(currentScreen, width)

  const handleTheme = () => {
    const newTheme = localStorageTheme === 'LIGHT' ? 'DARK' : 'LIGHT'
    localStorage.setItem('theme', newTheme)
    setReRender(!reRender)
  }

  const handleResize = () => {
    dispatch(setWidth(window.innerWidth))
  }

  const handleScroll = () => {
    dispatch(setScroll(window.scrollY))
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [dispatch])

  useEffect(() => {
    const lenis = new Lenis({
      duration: 2,
    })

    function raf(time: number) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)
  }, [])

  return (
    <div
      className={
        localStorageTheme === 'LIGHT' ? 'appContainer' : 'appContainerDark'
      }
      style={{ height: `${screenHeight}px` }}
    >
      <NavigationBar handleTheme={handleTheme} />
      <Routes>
        <Route path="/" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/cv" element={<Cv />} />
      </Routes>
    </div>
  )
}

export default App
