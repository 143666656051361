import React, { useRef } from 'react'
import { MeshReflectorMaterial, useGLTF } from '@react-three/drei'

import * as THREE from 'three'

useGLTF.preload('/compassLid.glb')

const CompassLid = ({
  scale,
  position,
  material,
}: {
  scale: number
  position: [number, number, number]
  material: any
}) => {
  const meshRef = useRef<any>()

  if (meshRef.current) {
    meshRef.current.material.side = THREE.DoubleSide
  }
  //   @ts-ignore
  const { nodes } = useGLTF('/compassLid.glb')

  return (
    <>
      <group
        dispose={null}
        position={position}
        rotation={[0, 0, 0]}
        scale={scale}
      >
        <CompassMirror position={[0, 0.1, -0.0]} planeArgs={[1.42, 1.42]} />
        {/* BOTTOM */}
        <CompassMirror position={[0, 0.1, -0.76]} planeArgs={[1.1, 0.1]} />
        <CompassMirror position={[0, 0.1, -0.85]} planeArgs={[0.87, 0.1]} />
        {/* TOP */}
        <CompassMirror position={[0, 0.1, 0.76]} planeArgs={[1.1, 0.1]} />
        <CompassMirror position={[0, 0.1, 0.85]} planeArgs={[0.87, 0.1]} />
        {/* LEFT */}
        <CompassMirror position={[0.76, 0.1, 0]} planeArgs={[0.1, 1.1]} />
        <CompassMirror position={[0.86, 0.1, 0]} planeArgs={[0.1, 0.83]} />
        {/* RIGHT */}
        <CompassMirror position={[-0.76, 0.1, 0]} planeArgs={[0.1, 1.1]} />
        <CompassMirror position={[-0.86, 0.1, 0]} planeArgs={[0.1, 0.83]} />

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder.geometry}
          material={material}
          position={[0, 0.06, 0]}
          scale={[0.909, 0.015, 0.909]}
        />
      </group>
      <pointLight intensity={0.7} position={[0, 3.2, -2]} />
    </>
  )
}

export default CompassLid

const CompassMirror = ({
  position,
  planeArgs,
}: {
  position: [number, number, number]
  planeArgs: [number, number]
}) => {
  return (
    <mesh position={position} rotation={[-Math.PI / 2, 0, Math.PI]}>
      <planeGeometry args={planeArgs} />
      <MeshReflectorMaterial
        blur={[0, 0]} // Blur ground reflections (width, height), 0 skips blur
        mixBlur={0} // How much blur mixes with surface roughness (default = 1)
        mixStrength={0.25} // Strength of the reflections
        mixContrast={1} // Contrast of the reflections
        resolution={1000} // Off-buffer resolution, lower=faster, higher=better quality, slower
        mirror={1} // Mirror environment, 0 = texture colors, 1 = pick up env colors
        depthScale={1} // Scale the depth factor (0 = no depth, default = 0)
        minDepthThreshold={0} // Lower edge for the depthTexture interpolation (default = 0)
        maxDepthThreshold={1} // Upper edge for the depthTexture interpolation (default = 0)
        depthToBlurRatioBias={0.15} // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor = blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
        distortion={1} // Amount of distortion based on the distortionMap texture
        // distortionMap={distortionTexture} // The red channel of this texture is used as the distortion map. Default is null
        reflectorOffset={0} // Offsets the virtual camera that projects the reflection. Useful when the reflective surface is some distance from the object's origin (default = 0)
        fog={true}
        transparent
      />
    </mesh>
  )
}
