import '../css/CvHeader.css'
import { useSelector } from 'react-redux'
import { getThemeColors } from '../utils/ThemeUtils'

const CvHeader = () => {
  const { scroll } = useSelector((state: any) => state.app)
  const animationDistance = 500
  const maxTranslation = window.innerWidth / 2

  const translation = Math.min(
    (scroll / animationDistance) * maxTranslation,
    maxTranslation
  )

  const theme = localStorage.getItem('theme')
  const themeColor = getThemeColors(theme !== null ? theme : 'LIGHT')
  const borderColor =
    theme === 'LIGHT'
      ? `rgb(${250 - scroll},${250 - scroll},${250 - scroll})`
      : `rgb(${34 + scroll},${49 + scroll},${66 + scroll})`

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 100,
      }}
    >
      <h1
        className="cvHeader"
        style={{
          transform: `translateX(${translation}px)`,
          borderBottomColor: borderColor,
          color: themeColor.background,
        }}
      >
        Martin
      </h1>
      <h1
        className="cvHeader cvHeaderLast"
        style={{
          transform: `translateX(${-translation}px)`,
          borderBottomColor: borderColor,
          color: themeColor.background,
        }}
      >
        Sjørbotten
      </h1>
    </div>
  )
}

export default CvHeader
